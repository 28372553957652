import { generateClient } from "aws-amplify/api";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import service from "../common/service";
import { reset } from "../common/slices/globalActions";
import { listCheckInArchives } from "../common/graphql/customQueries";
import { getSmoothArchive } from "../common/graphql/queries";

const client = generateClient();

const initialState = {
    archive: [],
    date: null,
};

const archiveSlice = createSlice({
    name: "archive",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(reset, (state) => {
            return initialState;
        });
    },
    reducers: {
        setArchive: (state, action) => {
            state.archive = action.payload
        },
        setDate: (state, action) => {
            state.date = action.payload
        },
        clearArchive: () => {
            return initialState;
        }
    },
});

export const { clearArchive, setArchive, setDate } = archiveSlice.actions;
export default archiveSlice.reducer;

export const selectState = (state) => state.archive;
export const selectArchive = createSelector(selectState, (state) => state.archive);
export const selectArchiveDate = createSelector(selectState, (state) => state.date);

export const listArchive = (email, date, precision) => async (dispatch, getState) => {
    const archive = await service.listArchive(email, date, precision);
    dispatch(setArchive(archive));
    dispatch(setDate(date));
    return archive;
};

export const fetchArchives = (email, startDate, endDate, tolerance = 0.000001, window) => async (dispatch) => {
    const { data: { getSmoothArchive: resp } } = await client.graphql({
        query: getSmoothArchive,
        variables: {
            input: {
                email,
                startDate,
                endDate,
                tolerance,
                window,
            }
        }
    });
    return resp;
}
