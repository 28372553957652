import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { orderBy } from 'lodash'
import moment from "moment"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import getNextCheckIn from "../common/service/getNextCheckIn"
import { selectCompanyCheckIns, selectFilter, setActiveCheckIn, setFilter } from "../common/slices/checkIn"
import theme from "../common/theme"
import isNotification from "../common/utils/isNotification"
import isOnJourney from "../common/utils/isOnJourney"
import isOnShift from "../common/utils/isOnShift"
import { setModalState } from "../slices/app"
import Role from "./Role"
import { Status, StatusLastUpdate } from "./Status"
import { FooterButton, Table, TableData, TableFooter, TableHeader, TableRow } from './Table'
import { TabContent } from "./Tabs"
import { P } from "./Typography"
import { useContext } from "react"
import { PrivacyContext } from "../App"
import { filters } from "../common/constants"
import { MetaButton } from "./Buttons"

const TableInfo = styled(P)`
    padding: 0 0 1rem 1rem;
`

const TableOnline = () => {
    const privacy = useContext(PrivacyContext);
    const checkIns = useSelector(selectCompanyCheckIns);
    const filter = useSelector(selectFilter);
    const dispatch = useDispatch();
    const nav = useNavigate();
    const userList = orderBy(checkIns.filter(u => isOnShift(u.status) || isOnJourney(u.status)), ['status', 'user.shiftStart']);

    const onRowClick = (row) => {
        dispatch(setActiveCheckIn(row.userId));
        dispatch(setModalState({ modalName: 'USER_DETAILS', show: true }));
        nav('/');
    }

    return (
        <>
            <Table>
                <thead>
                    {
                        filter &&
                        <tr>
                            <TableHeader><MetaButton onClick={() => dispatch(setFilter(null))}>Reset</MetaButton></TableHeader>
                            <TableHeader colSpan={7}>Results Filtered: {filters[filter]}</TableHeader>
                        </tr>
                    }
                    <tr>
                        <TableHeader>Status</TableHeader>
                        <TableHeader>Location Updated</TableHeader>
                        <TableHeader>Name</TableHeader>
                        <TableHeader>Role</TableHeader>
                        <TableHeader>Shift Start</TableHeader>
                        <TableHeader>Last Check-In</TableHeader>
                        <TableHeader>Next Check-In</TableHeader>
                        <TableHeader>Shift Duration</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {
                        userList.map(checkIn => {
                            return (
                                <TableRow key={checkIn.userId} onClick={() => onRowClick(checkIn)}
                                    style={{
                                        borderLeft: isNotification(checkIn.status) ? `2px solid ${theme.palette.red}` : 'none'
                                    }}
                                >
                                    <TableData><Status status={checkIn.user.status} style={{ background: 'transparent', padding: 0, fontWeight: 400 }} /></TableData>
                                    <TableData><StatusLastUpdate checkIn={checkIn} style={{ background: 'none', padding: 0, color: 'white' }} title="" /></TableData>
                                    <TableData dataPrivacy={privacy}>{checkIn.user.firstName} {checkIn.user.lastName}</TableData>

                                    <TableData><Role role={checkIn.user.role} /></TableData>
                                    <TableData>{moment(checkIn.user.shiftStart).format("h:mm a")}</TableData>
                                    <TableData>{moment(checkIn.user.manualTimestamp).format("h:mm a")} - {moment(checkIn.user.manualTimestamp).fromNow()}</TableData>
                                    <TableData>{getNextCheckIn(checkIn.user).format("h:mm a")} - {getNextCheckIn(checkIn.user).fromNow()}</TableData>
                                    <TableData>{moment.duration(moment().diff(moment(checkIn.user.shiftStart))).format("*h:mm [hours]")}</TableData>
                                </TableRow>
                            )
                        })
                    }
                </tbody>
            </Table>
            {
                !userList.length && <TableInfo>There are no workers on shift</TableInfo>
            }
            <TableFooter>
                <FooterButton onClick={() => { }}><FontAwesomeIcon icon={faChevronLeft} /></FooterButton>
                <FooterButton onClick={() => { }}><FontAwesomeIcon icon={faChevronRight} /></FooterButton>
            </TableFooter>
        </>
    )
}

export default () => (
    <TabContent tabId={0}>
        <TableOnline />
    </TabContent>
)