import { generateClient } from "aws-amplify/api";
import { createJourney } from "../graphql/mutations";

const client = generateClient();

export default async (email, startAddress, endAddress, eta, route, riskLevel) => {
    try {
        const {
            data: { createJourney: newJourney },
        } = await client.graphql({
            query: createJourney,
            variables: {
                input: {
                    startAddress,
                    endAddress,
                    eta,
                    route,
                    riskLevel,
                    userId: email,
                },
            },
        });
        return newJourney;
    } catch (ex) {
        console.log("failed to insert journey", ex);
    }
};
