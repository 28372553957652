import { generateClient } from "aws-amplify/api";
import { setUserPassword } from "../common/graphql/mutations";

const client = generateClient();

export default async ({ username, password, permanent }) => {
    const {
        data: { setUserPassword: success },
    } = await client.graphql({
        query: setUserPassword,
        variables: {
            input: {
                username,
                password,
                permanent,
            },
        },
    });
    if(!success) {
        throw new Error("Failed to update password");
    }
};
