import { createSelector, createSlice, createAction, AnyAction } from "@reduxjs/toolkit";
import { confirmSignIn, getCurrentUser, signIn, signOut, signUp, resetPassword, confirmResetPassword } from "aws-amplify/auth";
import { reset } from "./globalActions";

const moduleName = "auth";

const initialState = {
    loading: false,
    user: false,
    totpCode: "",
};

const userSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(reset, () => initialState);
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setUser: (state, action) => {
            state.loading = false;
            state.user = action.payload || false;
        },
        setTOTP: (state, action) => {
            state.totpCode = action.payload;
        },
    },
});

export const { setLoading, setUser, setTOTP } = userSlice.actions;
export default userSlice.reducer;

export const selectState = (state) => state[moduleName];
export const selectUser = createSelector(selectState, (state) => state.user);
export const selectTotpCode = createSelector(selectState, (state) => state.totpCode);
export const selectLoading = createSelector(selectState, (state) => state.loading);

export const fetchUser =
    () =>
        async (dispatch) => {
            try {
                const user = await getCurrentUser();
                dispatch(setUser(user));
                return user;
            } catch (ex) {
                dispatch(setUser(null));
                return null;
            }
        };

export const login =
    (username, password) =>
        async (dispatch) => {
            const user = await signIn({ username, password });
            dispatch(setUser(user));
            return user;
        };

export const logout =
    () =>
        async (dispatch) => {
            try {
                await signOut();
                dispatch(reset());
            } catch (ex) {
                console.log('failed to logout', ex);
            }
        };

export const register =
    (username, password) =>
        async (dispatch) => {
            try {
                const user = await signUp({
                    username,
                    password,
                    autoSignIn: {
                        enabled: false,
                    },
                });
                dispatch(setUser(user));
                return user;
            } catch (ex) {
                console.log("ex", ex);
                alert(`Failed to register: ${ex.message}`);
            }
        };

// export const confirmSignUp =
//     (username, code, clientMetadata) =>
//         async () => {
//             try {
//                 // await Auth.confirmSignUp(username, code, { clientMetadata });
//             } catch (ex) {
//                 console.log("ex", ex);
//                 alert(`Failed to confirm sign up: ${ex.message}`);
//             }
//         };

// export const confirmSignIn =
//     (code, challengeName) =>
//         async (dispatch, getState) => {
//             try {
//                 // const user = selectUser(getState());
//                 // await Auth.confirmSignIn(user, code, challengeName);
//             } catch (ex) {
//                 console.log("ex", ex);
//                 alert(`Failed to get TOTP code: ${ex.message}`);
//             }
//         };

// export const getTOTPCode =
//     () =>
//         async (dispatch, getState) => {
//             try {
//                 // const user = await Auth.currentAuthenticatedUser();
//                 // const code = await Auth.setupTOTP(user);
//                 // dispatch(setTOTP(code));
//                 // return code;
//             } catch (ex) {
//                 console.log("ex", ex);
//                 alert(`Failed to get TOTP code: ${ex.message}`);
//             }
//         };

// export const verifyTOTPCode =
//     (code) =>
//         async (dispatch, getState) => {
//             try {
//                 // const user = await Auth.currentAuthenticatedUser();
//                 // await Auth.verifyTotpToken(user, code);
//                 // await Auth.setPreferredMFA(user, "TOTP");
//             } catch (ex) {
//                 console.log("ex", ex);
//                 alert(`Failed to verify TOTP code: ${ex.message}`);
//             }
//         };

export const newPassword =
    (password) =>
        async (dispatch, getState) => {
            return await confirmSignIn({
                challengeResponse: password
            })
        };

export const forgotPassword =
    (username) =>
        async (dispatch, getState) => {
            return await resetPassword({ username })
        };

export const forgotPasswordSubmit =
    (username, confirmationCode, newPassword) =>
        async (dispatch, getState) => {
            return await confirmResetPassword({ username, confirmationCode, newPassword })
        };
