import { generateClient } from "aws-amplify/api";
import { updateEmergencyContact } from "../graphql/mutations";
import insertContact from "./insertContact";

const client = generateClient();

const updateContact = async ({
    id,
    erpAreaId,
    name,
    notifyBy,
    email,
    phone,
    priority,
    notificationTypes = ["SHIFT_ACCIDENT", "SHIFT_NOT_RESPONDING", "SHIFT_PANIC", "JOURNEY_ACCIDENT", "JOURNEY_NOT_RESPONDING", "JOURNEY_PANIC"],
}) => {
    try {
        let contact;
        let notifyBySMS = !!notifyBy.find((nb) => nb.value === "SMS");
        let notifyByEmail = !!notifyBy.find((nb) => nb.value === "EMAIL");
        if (id) {
            const { data } = await client.graphql({
                query: updateEmergencyContact,
                variables: {
                    input: {
                        id,
                        name,
                        email,
                        priority,
                        erpAreaId,
                        notifyBySMS,
                        notifyByEmail,
                        notificationTypes: [
                            "SHIFT_ACCIDENT",
                            "SHIFT_PANIC",
                            "JOURNEY_ACCIDENT",
                            "JOURNEY_PANIC",
                        ],
                        phone,
                    },
                },
            });
            contact = data.updateEmergencyContact;
        } else {
            contact = await insertContact({
                name,
                email,
                priority,
                erpAreaId,
                notifyBySMS,
                notifyByEmail,
                notificationTypes: ["SHIFT_ACCIDENT", "SHIFT_PANIC", "JOURNEY_ACCIDENT", "JOURNEY_PANIC"],
                phone,
            });
        }
        return contact;
    } catch (ex) {
        console.log("failed to update contact", ex);
    }
};

export default updateContact;
