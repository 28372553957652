import { generateClient } from "aws-amplify/api";
import { listCheckIns } from "../graphql/customQueries";

const client = generateClient();

export default async () => {
    try {
        const {
            data: {
                listCheckIns: { items: checkIns },
            },
        } = await client.graphql({
            query: listCheckIns,
            variables: {
                limit: 99999,
            },
        });
        return checkIns;
    } catch (ex) {
        console.warn("get checkins failed", ex);
    }
};
