import { faChevronDown, faChevronUp, faCircleDown, faCirclePlus, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createContext, useContext, useState } from 'react';
import styled from 'styled-components';
import theme from '../common/theme';
import { ButtonIcon, P } from './Typography';
import moment from 'moment';

const AccordionContainer = styled.div`
    font-size: 0.9rem;
`

const HeaderData = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const AccordionContext = createContext();

export const Accordion = ({ children, initialOpen = -1 }) => {
    const [active, setActive] = useState(initialOpen);
    let context = {
        active,
        setActive,
    };

    return (
        <AccordionContext.Provider value={context}>
            {children}
        </AccordionContext.Provider>
    )
}

const Header = styled.div`
    gap: 0.5rem;
    display: flex;
    padding: 0.5rem 1rem;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    border-bottom: 1px solid ${theme.palette.border};
    background: ${props => props.active ? theme.palette.tableActive : 'transparent'};

    &:hover {
        background: ${theme.palette.tableActive};
    }
`

const AccordionHeader = ({ active, title, createdAt, updatedAt, erpAreas, onClick, actions, style }) => {
    return (
        <Header
            onClick={onClick}
            active={active}
        >
            <HeaderData>
                {
                    active ?
                        <ButtonIcon icon={faChevronDown} color={theme.palette.lightBlue} /> :
                        <ButtonIcon icon={faChevronUp} />
                }
                <P noMargin style={{ lineHeight: '2rem', marginRight: 'auto', color: active ? theme.palette.lightBlue : 'inherit', fontWeight: active ? 700 : 'inherit' }}>{title}</P>
            </HeaderData>
            <HeaderData>
                {moment(createdAt).format("YYYY/MM/DD")}
            </HeaderData>
            <HeaderData>
                {moment(updatedAt).format("YYYY/MM/DD [at] h:mm a")}
            </HeaderData>
            <HeaderData>
                {erpAreas}
                {
                    active &&
                    <div style={{ marginLeft: 'auto' }}>
                        {actions}
                    </div>
                }
            </HeaderData>
        </Header>
    )
}

const AccordionSubHeader = ({ active, title, onClick, actions, style }) => {
    return (
        <div
            onClick={onClick}
            style={{
                display: 'flex',
                padding: '0.5rem 3rem',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: active ? 'none' : `1px solid rgba(41, 44, 84, 1)`,
                background: active ? theme.palette.tableActive : 'transparent',
                ...style
            }}>
            {
                active ?
                    <ButtonIcon icon={faChevronDown} color={theme.palette.lightBlue} /> :
                    <ButtonIcon icon={faChevronUp} />
            }
            <P noMargin style={{ marginRight: 'auto', color: active ? theme.palette.lightBlue : 'inherit', fontWeight: active ? 700 : 400 }}>{title}</P>
            {
                active &&
                <div>
                    {actions}
                </div>
            }
        </div>
    )
}

export const AccordionItem = ({ children, header, id, actions, createdAt, updatedAt, erpAreas }) => {
    const ctx = useContext(AccordionContext);
    const active = ctx.active === id;
    return (
        <AccordionContainer>
            <AccordionHeader
                active={active}
                onClick={() => active ? ctx.setActive(-1) : ctx.setActive(id)}
                title={header}
                actions={actions}
                createdAt={createdAt}
                updatedAt={updatedAt}
                erpAreas={erpAreas}
            />
            {
                active &&
                <div style={{
                    background: active ? theme.palette.tableActive : 'transparent',
                    borderBottom: `1px solid ${theme.palette.lightBlue}`
                }}>
                    {children}
                </div>
            }
        </AccordionContainer>
    )
}

export const AccordionSubItem = ({ children, header, id, actions, border = false }) => {
    const ctx = useContext(AccordionContext);
    const active = ctx.active === id;
    return (
        <AccordionContainer>
            <AccordionSubHeader
                active={active}
                onClick={() => active ? ctx.setActive(-1) : ctx.setActive(id)}
                title={header}
                actions={actions}
            />
            {
                active &&
                <div style={{
                    background: active ? theme.palette.tableActive : 'transparent',
                    borderBottom: active ? `1px solid ${theme.palette.lightBlue}` : 'none',
                    padding: '0 1rem 1rem 3rem',
                }}>
                    {children}
                </div>
            }
        </AccordionContainer >
    )
}