import { faChevronLeft, faChevronRight, faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useContext, useEffect, useState } from "react"
import { formatPhoneNumber } from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import styled from "styled-components"
import { PrivacyContext } from "../App"
import { listUsers, nextUserPage, prevUserPage, selectUser, selectUserList, setActiveUser } from '../common/slices/user'
import friendlyProtocol from "../common/utils/friendlyProtocol"
import { AdminButton } from "./Buttons"
import ModalAddUser from "./ModalAddUser"
import Role from "./Role"
import Search from "./Search"
import { Status } from "./Status"
import { FooterButton, Table, TableData, TableFooter, TableHeader, TableRow } from './Table'
import { TabContent } from "./Tabs"
import { ButtonIcon } from "./Typography"

const ScreenHeader = styled.div`
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`

const TableUser = () => {
    const privacy = useContext(PrivacyContext);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const dispatch = useDispatch();

    const userList = useSelector(selectUserList);
    const user = useSelector(selectUser);

    useEffect(() => {
        dispatch(listUsers(user.companyId, user.role))
    }, [dispatch, user.companyId, user.role])

    const onRowClick = (u) => {
        dispatch(setActiveUser(u.email))
        setShowAddUserModal(true);
    }

    return (
        <>
            <ScreenHeader>
                <Search />
                <AdminButton onClick={() => setShowAddUserModal(true)}><ButtonIcon icon={faPlusCircle} /> Add New User</AdminButton>
            </ScreenHeader>
            <Table>
                <thead>
                    <tr>
                        <TableHeader>Status</TableHeader>
                        <TableHeader>Name</TableHeader>
                        {
                            user.role === "OPERATOR" &&
                            <TableHeader>Company</TableHeader>
                        }
                        <TableHeader>Role</TableHeader>
                        <TableHeader>Email</TableHeader>
                        <TableHeader>Phone Number</TableHeader>
                        <TableHeader>Protocol</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {
                        userList.map(u => {
                            return (
                                <TableRow key={u.email} onClick={() => onRowClick(u)}>
                                    <TableData><Status status={u.status} style={{ padding: 0, background: 'transparent', fontWeight: 400 }} /></TableData>
                                    <TableData dataPrivacy={privacy}>{u.firstName} {u.lastName}</TableData>
                                    {
                                        user.role === "OPERATOR" &&
                                        <TableData dataPrivacy={privacy}>{u?.company?.name}</TableData>
                                    }
                                    <TableData><Role role={u.role} /></TableData>
                                    <TableData dataPrivacy={privacy}>{u.email}</TableData>
                                    <TableData dataPrivacy={privacy}>{formatPhoneNumber(u.phoneNumber)}</TableData>
                                    <TableData>{friendlyProtocol(u.checkInProtocol)}</TableData>
                                </TableRow>
                            )
                        })
                    }
                </tbody>
            </Table>
            <TableFooter>
                <FooterButton onClick={() => dispatch(prevUserPage(user.companyId, user.role))}><FontAwesomeIcon icon={faChevronLeft} /></FooterButton>
                <FooterButton onClick={() => dispatch(nextUserPage(user.companyId, user.role))}><FontAwesomeIcon icon={faChevronRight} /></FooterButton>
            </TableFooter>
            {showAddUserModal && <ModalAddUser show onClose={() => {
                setShowAddUserModal(false)
                dispatch(setActiveUser(null));
            }} />}
        </>
    )
}

export default () => (
    <TabContent tabId={1}>
        <TableUser />
    </TabContent>
)