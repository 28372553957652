import { generateClient } from "aws-amplify/api";
import insertCheckIn from "./insertCheckIn";
import { updateStatus } from "../graphql/customQueries";

const client = generateClient();

export default async ({ email, companyId, status, shiftStart, manualTimestamp, location, battery, checkInProtocol }) => {
    try {
        if (!email) throw new Error("Must specify an email address");

        console.log('sending status update, inputs: ', { email, companyId, status, shiftStart, manualTimestamp, location, battery, checkInProtocol })

        const { data } = await client.graphql({
            query: updateStatus,
            variables: {
                updateCheckInInput: {
                    userId: email,
                    companyId,
                    status,
                    location,
                    timestamp: new Date(),
                    battery,
                },
                updateUserInput: {
                    email,
                    status,
                    manualTimestamp,
                    checkInProtocol,
                    shiftStart,
                },
            },
        });
        return data;
    } catch (ex) {
        console.log("Failed to update status", ex);
        if (ex?.data?.updateUser !== null && ex?.data?.updateCheckIn === null) {
            console.log("failed to update checkin, attempting insert");
            const checkIn = await insertCheckIn({ email, status, companyId, location, battery });
            return {
                ...ex.data,
                updateCheckIn: checkIn,
            };
        }

        throw new Error(`Failed to update status and update checkin: ${ex.message}`);
    }
};
