import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import styled from "styled-components"
import { TabContent } from "./Tabs"
import { ButtonIcon, H2, P } from "./Typography"

import { faExpand } from "@fortawesome/free-solid-svg-icons"
import moment from "moment"
import { useContext } from "react"
import { useForm } from "react-hook-form"
import { ErrorContext, PrivacyContext } from "../App"
import { selectUser } from "../common/slices/user"
import theme from "../common/theme"
import { fetchArchives } from "../slices/archive"
import { Accordion, AccordionItem } from "./Accordion"
import { BlueButton, ButtonBase } from "./Buttons"
import FormDatePicker from "./FormDatePicker"
import FormSelectUser from "./FormSelectUser"
import FormTextInput from "./FormTextInput"
import Map from "./Map"
import MarkerArchive from "./MarkerArchive"
import Modal from "./Modal"
import Row from "./Row"
import ModalLoading from "./ModalLoading"


const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 1rem;

    filter: ${props => props.dataPrivacy ? 'blur(0.4rem)' : 'none'};
`

const Controls = styled.div`
    z-index: 1000;
    position: absolute;
    top: 1rem;
    left: 1rem;
    display: flex;
    flex-direction: column;
    background: ${theme.palette.border};
    padding: 1rem;
    gap: 1rem;
    max-width: 500px;
`

const TabArchiveReport = () => {
    const errorContext = useContext(ErrorContext);
    const privacy = useContext(PrivacyContext);
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const [archives, setArchives] = useState([]);
    const [dates, setDates] = useState({ startDate: null, endDate: null });
    const mapRef = useRef();

    const recenter = () => {
        if (archives.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            archives?.map(({ location }) => bounds.extend(location))
            mapRef.current.state.map.fitBounds(bounds, 15);
        }
    }

    useEffect(() => {
        setDates({
            startDate: moment().subtract(14, 'd').toDate(),
            endDate: moment().toDate()
        })
    }, [])

    useEffect(() => {
        recenter();
    }, [archives])

    const {
        control,
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
        watch,
    } = useForm({
        values: {
            window: 5,
            precision: 0.000001,
            startDate: dates.startDate,
            endDate: dates.endDate,
        }
    });

    const startDate = watch("startDate");
    const endDate = watch("endDate");

    const fieldProps = { control, errors };

    const onSubmit = async (data) => {
        try {
            const startDate = moment(data.startDate).startOf('d');
            const endDate = moment(data.endDate).endOf('d');
            setArchives([]);
            for await (const { value, label } of data.users) {
                const resp = await dispatch(fetchArchives(value, startDate, endDate, data.precision, data.window));
                setArchives(a => [...a, ...resp]);
            }
            console.log('finished loading');
        } catch (ex) {
            errorContext.setError(`Oops! Something went wrong. Please try again. If the problem persists, contact support.<br /><br />Message for Support: <em>${ex.message}</em>`)
        }
    }

    return (
        <Content dataPrivacy={privacy}>
            <Controls>
                <Accordion>
                    <AccordionItem id={0} header="Report Controls" actions={
                        archives.length ? <ButtonBase onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            recenter()
                        }}>{archives.length} <ButtonIcon icon={faExpand} /></ButtonBase> : null
                    }>
                        <Content>
                            <Row>
                                <FormDatePicker {...fieldProps} name="startDate" label="Start Date" placeholder="Select start date" rules={{ required: true }} maxDate={endDate} />
                                <FormDatePicker {...fieldProps} name="endDate" label="End Date" placeholder="Select end date" rules={{ required: true }} minDate={startDate} />
                            </Row>
                            <Row>
                                <FormTextInput {...fieldProps} name="precision" label="Precision" placeholder="Enter desired precison" disabled={user.role !== "OPERATOR"} />
                                <FormTextInput {...fieldProps} name="window" label="Minutes Between Updates" placeholder="Enter desired resolution (0.5 - 10)" rules={{ required: true, min: 0.5, max: 10 }} />
                            </Row>
                            <FormSelectUser {...fieldProps} name="users" label="Users" placeholder="Select Users" isMulti role={user?.role} companyId={user?.companyId} rules={{ required: true }} />
                            <P>Selecting long date ranges could result in report generation failure.  Please contact support if you encounter errors and larger reports are required.</P>
                            <BlueButton onClick={handleSubmit(onSubmit)} disabled={isSubmitting || !isValid}>Generate Report</BlueButton>
                        </Content>
                    </AccordionItem>
                </Accordion>
            </Controls>
            <Map ref={mapRef} options={{ styles: [], disableDefaultUI: true, fullscreenControl: true }}>
                {archives.map(a => <MarkerArchive key={`${a.email}-${a.timestamp}`} checkIn={a} />)}
            </Map>
            {isSubmitting && <ModalLoading />}
        </Content>
    )
}

export default () => (
    <TabContent tabId={0} style={{ flex: 1 }}>
        <TabArchiveReport />
    </TabContent>
)