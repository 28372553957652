import { faArrowCircleUp, faBattery0, faBatteryFull, faBatteryHalf, faBatteryQuarter, faBatteryThreeQuarters, faEdit, faLeaf, faPhone, faPlug, faRoute, faStreetView, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { statuses } from '../common/constants';
import { requestLocation, selectActiveCheckIn, setActiveCheckIn, updateCheckIn } from '../common/slices/checkIn';
import { fetchUserEmergencyContacts, selectUserEmergencyContacts, setActiveUser, updateUser } from '../common/slices/user';
import theme from '../common/theme';
import friendlyBatteryState from "../common/utils/friendlyBatteryState";
import friendlyProtocol from '../common/utils/friendlyProtocol';
import isNotification from '../common/utils/isNotification';
import isOff from '../common/utils/isOff';
import { selectLoading, setLoading } from '../slices/app';
import { clearArchive, listArchive, selectArchive, selectArchiveDate } from '../slices/archive';
import ArchiveDetails from './ArchiveDetails';
import Avatar from './Avatar';
import { BlueButton, ButtonBase, GreenButton, MetaButton, NavButton, RedButton } from './Buttons';
import Details from './Details';
import Divider from './Divider';
import { ERPEmergencyContactList } from './ERP';
import HTMLSpan from './HTMLSpan';
import LastUpdate from './LastUpdate';
import LSDConverter from './LSDConverter';
import MapActiveCheckIn from "./MapActiveCheckIn";
import Modal from './Modal';
import ModalConfirm from './ModalConfirm';
import ModalSendPushNotification from './ModalSendPushNotification';
import NextCheckIn from './NextCheckIn';
import Role from './Role';
import Row from './Row';
import { ShiftStatus } from './Status';
import { EmptyTab, Tab, TabContainer, TabContent, TabsContainer } from './Tabs';
import { H1, H2, H3, P, P2 } from './Typography';
import { ErrorContext, PrivacyContext } from '../App';
import ModalLoading from './ModalLoading';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 1;    
    color: white;
    overflow: hidden;
    `

const Left = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    color: #fff;
    gap: 0.25rem;
    min-width: 425px;
    max-width: 525px;
`

const Right = styled.div`
    flex: 2.5;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 0.5rem;

    @media (max-width: 768px) {
        display: none;
    }
`

const WorkerDetailsTab = ({ checkIn }) => {
    const privacy = useContext(PrivacyContext);

    return (
        <TabContent tabId={0}>

            <H3>Worker Details</H3>
            <Details title="Company" data={checkIn?.company?.name} dataPrivacy={privacy} />
            <Row>
                <Details title="Phone Number" data={formatPhoneNumber(checkIn?.user?.phoneNumber)} dataPrivacy={privacy} />
                <Details title="Email / Username" data={checkIn?.user?.email} dataPrivacy={privacy} />
            </Row>
            {
                checkIn?.user?.contactNotes &&
                <Details title={`${checkIn?.user?.firstName}'s Notes`} data={<HTMLSpan data={checkIn?.user?.contactNotes} />} dataPrivacy={privacy} />
            }
            <Divider />

            <H3>Shift Information</H3>
            {
                checkIn?.status !== "OFF" ?
                    <Row>
                        <Details title="Shift Start (YYYY/MM/DD)" data={moment(checkIn?.user?.shiftStart).format("YYYY/MM/DD [-] h:mm a")} />
                        <Details title="Shift Duration" data={moment.duration(moment().diff(moment(checkIn?.user?.shiftStart), 'm'), 'm').format("*h:mm [h]")} />
                    </Row>
                    :
                    <P2 noMargin>{checkIn?.user?.firstName} is not on a shift, their last shift ended on {moment(checkIn?.user?.manualTimestamp).format("YYYY/MM/DD [at] h:mm a")}.</P2>
            }
            <H3 style={{ fontSize: '14px' }}>Location</H3>
            <LastUpdate checkIn={checkIn} style={{ marginBottom: '0.5rem' }} />

            <H3 style={{ fontSize: '14px' }}>Check-In</H3>
            <Row>
                <Details title="Last Check-In" data={`${moment(checkIn?.user?.manualTimestamp).format("h:mm a")} - ${moment(checkIn?.user?.manualTimestamp).fromNow()}`} />
                <Details title="Check-In Frequency" data={friendlyProtocol(checkIn?.user?.checkInProtocol)} />
            </Row>
            <NextCheckIn checkIn={checkIn} />
        </TabContent>
    )
}

const EmergencyContactsTab = ({ checkIn }) => {
    const contacts = useSelector(selectUserEmergencyContacts);
    const privacy = useContext(PrivacyContext);

    if (checkIn?.user?.erpArea) {
        return (
            <TabContent tabId={1}>
                <Row>
                    <Details title="Company" data={checkIn?.company?.name} dataPrivacy={privacy} />
                    <Details title="ERP Area" data={checkIn?.user?.erpArea?.name} dataPrivacy={privacy} />
                </Row>
                {
                    checkIn?.company?.contactNotes &&
                    <Details title="Company Notes" data={<HTMLSpan data={checkIn?.company?.contactNotes} />} dataPrivacy={privacy} />
                }
                {
                    checkIn?.user?.contactNotes &&
                    <Details title={`${checkIn?.user?.firstName} Notes`} data={<HTMLSpan data={checkIn?.user?.contactNotes} />} dataPrivacy={privacy} />
                }
                <H3>ERP Area Contacts</H3>
                {
                    checkIn?.user?.erpArea?.contactNotes &&
                    <Details title="ERP Area Notes" data={<HTMLSpan data={checkIn?.user?.erpArea?.contactNotes} />} style={{ marginBottom: '1rem' }} dataPrivacy={privacy} />
                }
                <ERPEmergencyContactList contacts={checkIn?.user?.erpArea?.emergencyContacts?.items} dataPrivacy={privacy} />
                {
                    contacts.length > 0 &&
                    <>
                        <Divider />
                        <H3>User Contacts</H3>
                        <ERPEmergencyContactList contacts={contacts} dataPrivacy={privacy} />
                    </>
                }
                {!checkIn?.user?.erpArea?.emergencyContacts?.items?.length && <P style={{ fontSize: '0.75rem', margin: 0, padding: 0 }}>{checkIn?.user?.erpArea?.contactInfo}</P>}
            </TabContent>
        )
    } else {
        return (
            <TabContent tabId={1}>
                <P noMargin><FontAwesomeIcon icon={faPhone} /> {checkIn?.company?.emergencyResponsePhoneNumber}</P>
            </TabContent>
        )
    }

}

const TelemetryTab = ({ checkIn }) => {
    const speed = (checkIn?.location.speed * 3.6).toFixed(2)

    const renderBatteryStatus = () => {
        let resp;
        if (checkIn?.battery && checkIn?.user?.mode !== 'GPS') {
            const { batteryState, batteryLevel, lowPowerMode } = checkIn.battery;

            switch (batteryState) {
                case 1:
                    let icon, color = theme.palette.green;
                    if (batteryLevel < 0.1) { icon = faBattery0; color = theme.palette.red }
                    else if (batteryLevel < 0.25) { icon = faBatteryQuarter; color = theme.palette.yellow }
                    else if (batteryLevel >= 0.25 && batteryLevel < 0.75) { icon = faBatteryHalf; }
                    else if (batteryLevel >= 0.75 && batteryLevel < 0.90) { icon = faBatteryThreeQuarters }
                    else { icon = faBatteryFull }
                    resp = (
                        <span>
                            <FontAwesomeIcon icon={icon} color={color} />
                            &nbsp;
                            {(batteryLevel * 100).toFixed(2)}%
                            &nbsp;
                            {lowPowerMode && <FontAwesomeIcon icon={faLeaf} color={theme.palette.red} title="Low power mode active" />}
                        </span>
                    )
                    break;
                case 2:
                    resp = (<span><FontAwesomeIcon icon={faPlug} color={theme.palette.green} /> {(batteryLevel * 100).toFixed(2)}%</span>)
                    break;
                case 3:
                    resp = (<span><FontAwesomeIcon icon={faBatteryFull} color={theme.palette.green} /> Full</span>)
                    break;
                default:
                    break;
            }
        }

        return resp;
    }

    const renderHeading = () => {
        return <span>
            {checkIn?.location?.heading?.toFixed(3)} &deg; &nbsp;
            <FontAwesomeIcon
                icon={faArrowCircleUp}
                style={{ transform: `rotate(${checkIn?.location.heading}deg)`, transition: 'all 1s' }}
                title={`${checkIn?.location.heading?.toFixed(0)}\u00B0 Heading`}
            />
        </span>
    }

    return (
        <TabContent tabId={2}>
            <H3>Device Overview</H3>
            <Row>
                <Details title="Mode" data={checkIn?.user?.mode || "CELLULAR"} />
                {
                    checkIn?.user?.mode === "GPS" &&
                    <Details title="Device IMEI" data={checkIn?.user?.satelliteDeviceId} />
                }

            </Row>
            <Row>
                <Details title="Battery" data={renderBatteryStatus()} />
                <Details title="Battery Status" data={friendlyBatteryState(checkIn?.battery?.batteryState)} />

            </Row>
            <Divider />
            <H3>Navigation Data</H3>
            <Row>
                <Details title="GPS Location" data={`${checkIn?.location.lat.toFixed(3)}, ${checkIn?.location.lng.toFixed(3)}`} />
                <Details title="Speed" data={`${speed} km/h`} />
                <div style={{ flex: 1 }}></div>
            </Row>
            <Row>
            </Row>
            <Row>
                <Details title="Heading" data={renderHeading()} />
                <Details title="Elevation" data={`${checkIn?.location.altitude?.toFixed(2)} meters`} />
                <Details title="Accuracy" data={`${checkIn?.location.accuracy?.toFixed(2)} meters`} />
            </Row>
            <Divider />
            <LSDConverter checkIn={checkIn} />
        </TabContent>
    )
}

const ArchiveTab = ({ checkIn, mapRef }) => {
    const [precision, setPrecision] = useState(2);
    const dispatch = useDispatch();
    const archiveDate = useSelector(selectArchiveDate);
    const loading = useSelector(selectLoading);
    const errorContext = useContext(ErrorContext);

    useEffect(() => {
        if (archiveDate) {
            getArchive(archiveDate);
        }
    }, [precision])

    const getArchive = async (date) => {
        dispatch(setLoading(true));
        const archive = await dispatch(listArchive(checkIn.userId, date, precision));
        if (archive?.length) {
            const bounds = new window.google.maps.LatLngBounds();
            archive.map(p => bounds.extend(p.location))
            mapRef.current.state.map.fitBounds(bounds, 15);
        }
        dispatch(setLoading(false));
    }

    const onDateClick = async (date) => {
        try {
            if (!moment(date).isSame(archiveDate, 'day')) {
                await getArchive(date);
            } else dispatch(clearArchive());
        } catch (ex) {
            errorContext.setError(`Failed to fetch archive.<br /><br />Message for Support: ${ex.message}`);
        } finally {
            dispatch(setLoading(false));
        }
    }

    return (
        <TabContent tabId={3}>
            <P noMargin>Select a date on the calendar to view shift details.</P>
            <Calendar onChange={onDateClick} value={archiveDate} tileDisabled={() => loading} calendarType={'US'} />
            <Row style={{ marginTop: '1rem' }}>
                <MetaButton onClick={() => setPrecision(2)} disabled={precision === 2 || !archiveDate}>Summary</MetaButton>
                <MetaButton onClick={() => setPrecision(0.25)} disabled={precision === 0.25 || !archiveDate}>Detailed</MetaButton>
                <MetaButton onClick={() => dispatch(clearArchive())} disabled={!archiveDate}>Reset</MetaButton>
            </Row>
            <Divider />
            {loading && <P>Processing {precision === 2 ? 'summary' : 'detailed'} shift archive...</P>}
            {!loading && <ArchiveDetails precision={precision} />}
            {loading && <ModalLoading />}
        </TabContent>
    )

}

const ModalUserDetails = ({ show, onClose }) => {
    const errorContext = useContext(ErrorContext);
    const checkIn = useSelector(selectActiveCheckIn);
    const archive = useSelector(selectArchive);
    const loading = useSelector(selectLoading);
    const dispatch = useDispatch();
    const [offShiftConfirm, setOffShiftConfirm] = useState(false);
    const [showSendPushNotification, setShowSendPushNotification] = useState(false);
    const [focusUser, setFocusUser] = useState(false);
    const mapRef = useRef();

    const privacy = useContext(PrivacyContext)

    useEffect(() => {
        (async () => {
            try {
                if (checkIn) {
                    await dispatch(fetchUserEmergencyContacts(checkIn.userId));
                }
            } catch (ex) {
                errorContext.setError(`Failed to fetch user's emergency contacts.  Please try your action again.  If the issue persists, contact support.<br /><br />Message for Support: ${ex.message}`)
            }
        })()
    }, [checkIn?.userId])

    useEffect(() => {
        //Called when the user presses the back button instead of clicking the 'X' on the modal
        return () => {
            // dispatch(setActiveCheckIn(null)); //Consider removing this in the future.  Makes it so the main map won't follow a user around
            dispatch(clearArchive());
        }
    }, []);

    useEffect(() => {
        if (mapRef.current && focusUser) {
            mapRef.current.state.map.setCenter(new window.google.maps.LatLng(checkIn?.location?.lat, checkIn?.location?.lng))
        }
    }, [mapRef.current, checkIn?.location, focusUser])

    useEffect(() => {
        if (archive?.length) {
            setFocusUser(false);
        }
    }, [archive])

    const onUpdateUser = (status) => async () => {
        const newStatus = status.split("_")[0] + '_OK';
        dispatch(setLoading(true));
        await dispatch(updateCheckIn(checkIn.userId, newStatus))
        await dispatch(updateUser({ email: checkIn.userId, status: status.split("_")[0], manualTimestamp: new Date() }))
        dispatch(setLoading(false));
    }

    const onCenterCheckIn = () => {
        if (!focusUser) {
            mapRef.current.state.map.setZoom(17);
            dispatch(clearArchive());
        }
        setFocusUser(!focusUser);
    }

    const onCenterArchive = () => {
        const bounds = new window.google.maps.LatLngBounds();
        archive?.map(({ location }) => bounds.extend(location))
        mapRef.current.state.map.fitBounds(bounds, 15);
    }


    const onUserDetailsClose = (e) => {
        dispatch(clearArchive());
        // dispatch(setActiveCheckIn(null)); //Consider removing this in the future.  Makes it so the main map won't follow a user around
        onClose(e);
    }

    const onRequestLocation = async () => {
        try {
            dispatch(setLoading(true));
            await dispatch(requestLocation(checkIn.userId))
        } catch (ex) {
            errorContext.setError(`Failed to request users location.<br /><br />Message for Support: <em>${ex.message}</em>`)
        } finally {
            dispatch(setLoading(false));
        }
    }

    if (!checkIn) {
        return null;
    }


    return (
        <Modal
            show={show}
            onClose={onUserDetailsClose}
            bodyStyle={{
                backgroundColor: theme.palette.blue,
                borderColor: theme.palette.blue,
                minHeight: '90%',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
            }}
            containerStyle={{
                alignItems: 'flex-start',
                paddingTop: '1rem'
            }}
        >
            <Container>
                <Row style={{ flex: 0 }}>
                    <H1
                        style={{ marginRight: 'auto' }}
                        onClick={() => navigator.clipboard.writeText(checkIn?.userId)}
                    >Worker Details</H1>
                    <ButtonBase onClick={() => onUserDetailsClose()}><FontAwesomeIcon icon={faTimesCircle} color="white" size="2x" /></ButtonBase>
                </Row>
                <Divider />
                <Row style={{ alignItems: 'stretch' }}>

                    <Left>
                        <Row style={{ flex: 0 }}>
                            <Avatar avatar={checkIn?.user?.avatar} />
                            <div style={{ flex: 1 }}>
                                <Role role={checkIn?.user?.role} />
                                <H2 noMargin dataPrivacy={privacy}>{checkIn?.user?.firstName} {checkIn?.user?.lastName}</H2>
                            </div>
                        </Row>
                        <ShiftStatus checkIn={checkIn} />
                        <TabContainer>

                            <TabsContainer style={{ marginBottom: '1rem' }}>
                                <Tab tabId={0}>Overview</Tab>
                                <Tab tabId={1}>Emergency Contacts</Tab>
                                <Tab tabId={2}>Telemetry</Tab>
                                <Tab tabId={3}>Archive</Tab>
                                <EmptyTab />
                            </TabsContainer>
                            <WorkerDetailsTab checkIn={checkIn} />
                            <EmergencyContactsTab checkIn={checkIn} />
                            <TelemetryTab checkIn={checkIn} />
                            <ArchiveTab checkIn={checkIn} mapRef={mapRef} />


                        </TabContainer>
                        <Divider style={{ marginTop: 'auto' }} />
                        <Row style={{ flex: 0, gap: '0.25rem' }}>

                            {
                                isNotification(checkIn?.status) &&
                                <GreenButton style={{ flex: 1 }} onClick={onUpdateUser(checkIn?.status)} disabled={loading}>Acknowledge</GreenButton>
                            }
                            {
                                !isOff(checkIn?.status) &&
                                <>
                                    <BlueButton style={{ flex: 1 }} onClick={onRequestLocation} disabled={loading}>Request Location</BlueButton>
                                    <BlueButton style={{ flex: 1 }} onClick={() => setShowSendPushNotification(true)} disabled={loading}>Send Message</BlueButton>
                                </>
                            }
                        </Row>


                        <RedButton onClick={async () => setOffShiftConfirm(true)} disabled={checkIn?.status === statuses.OFF || loading}>Set Off Shift</RedButton>
                    </Left>
                    <Right>
                        <Row style={{ flex: 0, padding: '0.5rem', gap: '1rem' }}>
                            <NavButton onClick={onCenterCheckIn} style={{ color: focusUser ? theme.palette.lightBlue : '#fff' }}><FontAwesomeIcon icon={faStreetView} /> {focusUser ? 'User Focused' : 'Focus User'}</NavButton>
                            <NavButton onClick={onCenterArchive} disabled={!archive.length}><FontAwesomeIcon icon={faRoute} />Focus Archive</NavButton>
                        </Row>

                        <MapActiveCheckIn checkIn={checkIn} privacy={privacy} ref={mapRef} />
                    </Right>
                </Row>

            </Container>

            {showSendPushNotification &&
                <ModalSendPushNotification userId={checkIn?.userId} onClose={() => setShowSendPushNotification(false)} />
            }


            {offShiftConfirm && <ModalConfirm
                title="Confirm Set Off Shift"
                description={`
                    Are you sure you want to put ${checkIn?.user?.firstName} ${checkIn?.user?.lastName} off shift?<br /><br />
                    <b>This should only be used as a last resort</b>.  Taking a user off shift from the backend will result in their device continuing to send location updates in an OFF status until location tracking is disabled on their device.
                `}
                confirmButtonTitle='Set Off Shift'
                onConfirm={() => {
                    dispatch(updateCheckIn(checkIn?.userId, statuses.OFF));
                    dispatch(updateUser({ email: checkIn?.userId, status: statuses.OFF, manualTimestamp: new Date() }));
                    setOffShiftConfirm(false)
                }}
                onClose={() => setOffShiftConfirm(false)} />}
        </Modal>
    )
}

export default ModalUserDetails