import { useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import styled from "styled-components"
import { TabContent } from "./Tabs"

import { faEdit, faPhoneVolume } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { orderBy } from 'lodash'
import { formatPhoneNumber } from "react-phone-number-input"
import { fetchCompany, selectActiveCompany } from "../common/slices/company"
import { selectUser } from "../common/slices/user"
import theme from "../common/theme"
import { AccordionSubTable, AccordionTable, AccordionTableHeader, ERPData, HeaderData } from "./Accordion"
import { Accordion, AccordionItem } from "./AccordionCompany"
import Badge from "./Badge"
import { AdminButton, ButtonBase } from "./Buttons"
import Details from "./Details"
import { tzOptions } from "./FormSelectTimezone"
import HTMLSpan from "./HTMLSpan"
import ModalManageCompany from "./ModalManageCompany"
import ModalManageCompanyERP from "./ModalManageCompanyERP"
import Row from "./Row"
import { ScreenHeader } from "./Table"
import { ButtonIcon, H2 } from "./Typography"
import moment from "moment"


const CompanyDetails = styled.div`
    background: ${theme.palette.tableRow};
    padding: 1rem;
    max-width: 900px;
`

const types = {
    SHIFT_NOT_RESPONDING: 'Missed Check-in',
    SHIFT_PANIC: 'Shift Panic',
    SHIFT_ACCIDENT: 'Shift Accident',
    JOURNEY_ACCIDENT: 'Journey Accident',
    JOURNEY_PANIC: "Journey Panic",
}

const TabManageCompany = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const company = useSelector(selectActiveCompany);
    const [showEditCompany, setShowEditCompany] = useState(false);
    const [showEditCompanyERP, setShowEditCompanyERP] = useState(false);

    useEffect(() => {
        if (user) {
            dispatch(fetchCompany(user.companyId));
        }
    }, [user])

    if (!company) {
        return null;
    }

    return (
        <>
            <CompanyDetails>
                <Row>
                    <H2 style={{ flex: 1 }}>{company.name}</H2>
                    <ButtonBase onClick={() => setShowEditCompany(true)}><FontAwesomeIcon icon={faEdit} size='1x' /></ButtonBase>
                </Row>
                <Row style={{ alignItems: 'flex-start' }}>
                    <div style={{ flex: 1, gap: '0.25rem', display: 'flex', flexDirection: 'column' }}>
                        <Details title="Customer Since" data={`${moment(company.createdAt).format("MMMM D, YYYY")}`} />
                        <Details title="Phone Number" data={company.phoneNumber} />
                        <Details title="Address" data={company.address} />
                        <Details title="Timezone" data={tzOptions.find(tz => tz.value === company.utcOffset)?.label} />
                        <Details title="Exclude Max Speed on Report" data={company.reportExcludeMaxSpeed ? 'Yes' : 'No'} />
                    </div>
                    <Details title={`${company.name} Notes`} data={<HTMLSpan data={company.contactNotes} />} style={{ flex: 2 }} />
                </Row>
            </CompanyDetails>

            <H2>ERP Groups</H2>
            <ScreenHeader>
                <div style={{ flex: 1 }} />
                <AdminButton onClick={() => setShowEditCompanyERP(true)}><ButtonIcon icon={faPhoneVolume} /> Manage ERP Groups</AdminButton>
            </ScreenHeader>
            <AccordionTable>
                <AccordionTableHeader>
                    <HeaderData>Name</HeaderData>
                    <HeaderData>Date Created</HeaderData>
                    <HeaderData>Date Modified</HeaderData>
                    <HeaderData># of Contacts</HeaderData>
                </AccordionTableHeader>

                <Accordion>
                    {
                        orderBy(company.erpAreas.items, c => c.name?.toLowerCase(), 'asc')?.map((erpArea, ci) => (
                            <AccordionItem
                                id={ci}
                                header={erpArea.name}
                                createdAt={erpArea.createdAt}
                                updatedAt={erpArea.updatedAt}
                                erpAreas={erpArea?.emergencyContacts?.items?.length}
                                key={erpArea.id}
                            >
                                <AccordionTable style={{ background: theme.palette.tableActive, margin: '0 0 1rem 1rem', borderTop: `1px solid ${theme.palette.lightBlue}` }}>
                                    {erpArea.contactNotes && <Details title="Notes" data={<HTMLSpan data={erpArea.contactNotes} />} style={{ margin: '1rem 0' }} />}
                                    <AccordionTableHeader>
                                        <HeaderData>Priority</HeaderData>
                                        <HeaderData>Name</HeaderData>
                                        <HeaderData>Phone Number</HeaderData>
                                        <HeaderData>Email</HeaderData>
                                        <HeaderData>Notify By</HeaderData>
                                        <HeaderData>Notification Types</HeaderData>
                                    </AccordionTableHeader>
                                    {orderBy(erpArea.emergencyContacts?.items, "priority").map(contact => (
                                        <AccordionSubTable key={contact.id}>
                                            <ERPData>{contact.priority}</ERPData>
                                            <ERPData>{contact.name}</ERPData>
                                            <ERPData>{formatPhoneNumber(contact.phone)}</ERPData>
                                            <ERPData>{contact.email}</ERPData>
                                            <ERPData>{contact.notifyBySMS ? <Badge color="#606060">sms</Badge> : ''} {contact.notifyByEmail ? <Badge color="#606060">email</Badge> : ''}</ERPData>
                                            <ERPData>{contact.notificationTypes?.map(type => types[type]).join(', ')}</ERPData>
                                        </AccordionSubTable>
                                    ))}
                                </AccordionTable>
                            </AccordionItem>
                        ))
                    }
                </Accordion>
            </AccordionTable>
            {showEditCompany && <ModalManageCompany company={company} onClose={() => setShowEditCompany(false)} />}
            {showEditCompanyERP && <ModalManageCompanyERP company={company} onClose={() => setShowEditCompanyERP(false)} />}
        </>
    )
}

export default () => (
    <TabContent tabId={4}>
        <TabManageCompany />
    </TabContent>
)