import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { roles } from "../common/constants"
import { selectUser } from '../common/slices/user'
import theme from '../common/theme'
import TableCompany from "./TableCompany"
import TableOnline from "./TableOnline"
import TableShiftSchedule from './TableShiftSchedule'
import TableUser from "./TableUser"
import TabManageCompany from './TabManageCompany'
import TabManageSatellite from './TabManageSatellite'
import { EmptyTab, Tab, TabContainer, TabsContainer } from "./Tabs"
import { H1 } from './Typography'
import { useContext } from 'react'
import { PrivacyContext } from '../App'

const ScreenAdmin = () => {
    const privacy = useContext(PrivacyContext);
    const user = useSelector(selectUser);


    return (
        <TabContainer style={{ padding: '1rem' }}>
            <H1 style={{ fontWeight: 700, fontSize: '35px', marginBottom: '2rem' }}>Admin</H1>
            <TabsContainer>
                <Tab tabId={0}><FontAwesomeIcon icon={faCircle} color={theme.palette.green} style={{ fontSize: '0.5rem', lineHeight: '0.5rem' }} /> Online</Tab>
                <Tab tabId={4}>Company</Tab>
                <Tab tabId={1}>Users</Tab>
                <Tab tabId={5}>Satellite</Tab>
                <Tab tabId={3}>Shift Schedule</Tab>
                {(!privacy && user.role === roles.OPERATOR) && <Tab tabId={2}>Companies</Tab>}
                <EmptyTab />
            </TabsContainer>

            <TableOnline />
            <TableUser />
            <TableCompany />
            <TableShiftSchedule />
            <TabManageCompany />
            <TabManageSatellite />
        </TabContainer>
    )
}

export default ScreenAdmin;
