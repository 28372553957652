import { generateClient } from "aws-amplify/api";
import { getSmoothArchive } from "../graphql/queries";
import moment from 'moment';

const client = generateClient();

export default async (email, date, precision = 0.5) => {
    const {
        data: {
            getSmoothArchive: archives,
        },
    } = await client.graphql({
        query: getSmoothArchive,
        variables: {
            input: {
                email,
                startDate: moment(date).startOf("day"),
                endDate: moment(date).endOf("day"),
                tolerance: 0.000001,
                window: precision,
            }
        },
    });
    return archives;
};
