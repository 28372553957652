import { generateClient } from "aws-amplify/api";
import { updateUser } from "../graphql/mutations";

const client = generateClient();

export default async (email, avatar) => {
    try {
        const {
            data: { updateUser: updatedUser },
        } = await client.graphql({
            query: updateUser,
            variables: {
                input: {
                    email,
                    avatar,
                },
            },
        });
        return updatedUser;
    } catch (ex) {
        console.error("Failed to update user", ex);
    }
};
