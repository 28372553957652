import validateIMEI from "../common/service/validateIMEI";
import FormTextInput from "./FormTextInput";

export default (props) => {
    return (
        <FormTextInput {...props} rules={{
            required: true,
            validate: {
                matchPattern: props.disabled ? () => true : validateIMEI,
            }
        }} />
    );
};
