import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import validateEmail from "../common/service/validateEmail";
import { setActiveCheckIn } from "../common/slices/checkIn";
import { deleteUser, selectActiveUser, selectUser, updateUser } from "../common/slices/user";
import { setModalState } from "../slices/app";
import { BlueButton, ButtonBase, RedButton } from "./Buttons";
import FormCheckbox from "./FormCheckbox";
import FormPhoneNumber from "./FormPhoneNumber";
import FormSelectCompany from "./FormSelectCompany";
import FormSelectERP from "./FormSelectERP";
import FormSelectProtocol from "./FormSelectProtocol";
import FormSelectRole from "./FormSelectRole";
import FormSelectSatelliteDeviceType from './FormSelectSatelliteDeviceType';
import FormTextArea from "./FormTextArea";
import FormTextInput from "./FormTextInput";
import Modal, { Container, Footer, Header } from './Modal';
import ModalEmergencyContacts from "./ModalEmergencyContacts";
import ModalSetPassword from "./ModalSetPassword";
import ModalShiftSchedule from './ModalShiftSchedule';
import Row from './Row';
import { H1 } from "./Typography";
import ModalConfirm from "./ModalConfirm";
import { PrivacyContext } from "../App";

const marshall = (user) => ({
    ...user,
    contactNotes: user?.contactNotes || "",
    companyId: { value: user?.company?.id, label: user?.company?.name },
    erpAreaId: { value: user?.erpArea?.id, label: user?.erpArea?.name }
})

const unmarshall = (user) => ({
    ...user,
    companyId: user.companyId.value,
    erpAreaId: user.erpAreaId.value,
})

const ModalAddUser = ({ show, onClose }) => {
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showSchedule, setShowSchedule] = useState(false);
    const [showContacts, setShowContacts] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const user = useSelector(selectUser);
    const activeUser = useSelector(selectActiveUser);
    const dispatch = useDispatch();
    const nav = useNavigate();
    const privacy = useContext(PrivacyContext);

    const onSaveClick = async (values) => {
        try {
            await dispatch(updateUser(unmarshall(values)))
            onClose();
        } catch (ex) {
            console.log(ex);
        }
    }

    const onDelete = async () => {
        await dispatch(deleteUser(activeUser))
        onClose();
    }

    const onSetPassword = () => {
        setShowPasswordModal(true);
    }

    const onShowSchedule = () => {
        setShowSchedule(true);
    }

    const onShowContacts = () => {
        setShowContacts(true);
    }

    const onFindOnMap = () => {
        dispatch(setActiveCheckIn(activeUser.email));
        dispatch(setModalState({ modalName: 'USER_DETAILS', show: true }));
        nav('/');
    }

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting, isValid, isDirty },
        setValue,
        watch,
    } = useForm({
        values: marshall(activeUser)
    })

    const fieldProps = { control, errors }
    const companyId = useWatch({ name: 'companyId', control });
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const role = watch('role');

    return (
        <Modal
            show={show}
            onClose={() => { if (!isDirty) { onClose() } }}
        >
            <Container dataPrivacy={privacy && activeUser}>
                <Header>
                    <H1 onClick={() => navigator.clipboard.writeText(activeUser?.email)}>{activeUser ? 'Update User' : 'Add New User'}</H1>
                    <ButtonBase onClick={onClose} disabled={isSubmitting}><FontAwesomeIcon icon={faTimesCircle} size='2x' /></ButtonBase>
                </Header>
                <Row>
                    <FormTextInput {...fieldProps} name="firstName" placeholder="First Name" label="First Name" rules={{ required: true }} />
                    <FormTextInput {...fieldProps} name="lastName" placeholder="Last Name" label="Last Name" rules={{ required: true }} />
                </Row>
                <Row>
                    <FormTextInput {...fieldProps} name="email" placeholder="Email Address" label="Email Address" disabled={activeUser} rules={{
                        required: true,
                        pattern: {
                            value: re,
                            message: "Invalid email"
                        },
                    }} />
                    <FormPhoneNumber {...fieldProps} name="phoneNumber" placeholder="Phone Number" label="Phone Number" rules={{ required: true }} defaultCountry="CA" />
                </Row>
                <Row>
                    <FormSelectCompany {...fieldProps} rules={{ required: true }} role={user?.role} companyId={user?.companyId} />
                    <FormSelectERP name="erpAreaId"  {...fieldProps} companyId={companyId.value} rules={{ required: true }} setValue={setValue} />
                </Row>
                <Row>
                    <Row>
                        <FormSelectRole {...fieldProps} rules={{ required: true }} disabled={activeUser?.role === "OPERATOR"} />
                        {role === 'SUPERVISOR' && <FormCheckbox containerStyle={{ flex: 0 }} {...fieldProps} name="excludeShiftReport" label="Exclude Shift Reports" />}
                    </Row>
                    <FormSelectProtocol {...fieldProps} rules={{ required: true }} />
                </Row>
                <FormTextArea name="contactNotes" placeholder="Enter notes for this user, ie. medical conditions, do not contact, etc" label="User Notes" {...fieldProps} />
                {/* <Row>
                    <FormSelectSatelliteDeviceType {...fieldProps} />
                    <FormTextInput {...fieldProps} name="satelliteDeviceId" placeholder="Satellite Device ID" label="Satellite Device ID" />
                    <FormPhoneNumber {...fieldProps} name="satelliteDeviceSMS" placeholder="Satellite Device Phone Number" label="Satellite Device Phone Number" />
                </Row> */}
                <Footer>
                    <BlueButton onClick={handleSubmit(onSaveClick)} disabled={isSubmitting || !isValid}>Save</BlueButton>
                    {activeUser && <BlueButton onClick={onSetPassword} disabled={isSubmitting} >Set Password</BlueButton>}
                    {activeUser && <BlueButton onClick={onFindOnMap} disabled={isSubmitting}>Find on Map</BlueButton>}
                    {activeUser && <BlueButton onClick={onShowContacts} disabled={isSubmitting}>Emergency Contacts</BlueButton>}
                    {activeUser && <BlueButton onClick={onShowSchedule} disabled={isSubmitting}>Schedule</BlueButton>}
                    {activeUser && <RedButton onClick={() => setShowConfirm(true)} disabled={isSubmitting} style={{ marginLeft: 'auto' }} >Delete</RedButton>}
                    <ButtonBase onClick={onClose} style={{ marginLeft: activeUser ? '0' : 'auto' }} disabled={isSubmitting}>Cancel</ButtonBase>
                </Footer>
                {showPasswordModal && <ModalSetPassword show onClose={() => setShowPasswordModal(false)} />}
                {showSchedule && <ModalShiftSchedule show onClose={() => setShowSchedule(false)} />}
                {showContacts && <ModalEmergencyContacts show onClose={() => setShowContacts(false)} />}
            </Container>
            {
                showConfirm &&
                <ModalConfirm
                    title="Delete Confirmation"
                    description={`
                    Are you sure you want to permanently delete ${activeUser?.firstName} ${activeUser?.lastName} and their associated data?<br /><br />
                        The following will be deleted:
                        <ul>
                            <li>User Account</li>
                            <li>Shift Archive</li>
                            <li>Shift Schedules</li>
                            <li>Emergency Contacts</li>
                            <li>Satellite Device Associations</li>
                        </ul>
                        Any usage logged by this user during the current invoice period will still be invoiced at the end of the invoice period.<br /><br />
                        This action is <b>permanent and cannot be undone</b>.
                    `
                    }
                    confirmButtonTitle="Confirm Delete"
                    onConfirm={handleSubmit(onDelete)}
                    onClose={() => setShowConfirm(false)}
                    disabled={isSubmitting}
                />
            }
        </Modal>
    )
}

export default ModalAddUser