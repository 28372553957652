import { faCirclePlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFieldArray, useWatch } from "react-hook-form";
import theme from "../common/theme";
import { ButtonBase, WhiteButton } from "./Buttons";
import Divider from "./Divider";
import FormEmergencyContact from "./FormEmergencyContact";
import FormTextArea from "./FormTextArea";
import FormTextInput from "./FormTextInput";
import Row from "./Row";
import { ButtonIcon, H3, P } from "./Typography";

export default ({ name = "erpAreas.items", control, errors }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name,
        keyName: "keyId",
    })

    const fieldProps = { control, errors }

    return (
        <div style={{ paddingRight: '1rem', overflow: 'auto' }}>
            <P>ERP groups manage emergency contacts and operational processes to ensure timely support for workers.</P>
            {fields.map((item, index) => {
                const onDelete = () => {
                    remove(index);
                }

                return (
                    <div key={item.keyId} style={{ display: 'flex', gap: '1rem', flexDirection: 'column', background: theme.palette.border, padding: '1rem', marginBottom: '1rem' }}>
                        <Row style={{ alignItems: 'flex-start' }}>
                            <FormTextInput
                                {...fieldProps}
                                rules={{ required: true }}
                                placeholder="Enter ERP Group Name"
                                label="ERP Group Name"
                                name={`${name}.${index}.name`}
                                containerStyle={{ flex: 0 }}
                            />
                            <ButtonBase onClick={onDelete} style={{ padding: '0.25rem 0', alignSelf: 'center' }}><FontAwesomeIcon icon={faTrash} /></ButtonBase>
                        </Row>
                        <FormEmergencyContact
                            {...fieldProps}
                            name={`${name}.${index}.emergencyContacts.items`}
                        />
                        <FormTextArea {...fieldProps} name={`${name}.${index}.contactNotes`} label="Contact Notes" placeholder={`Enter contact notes for this ERP group`} />
                    </div>
                )
            })}
            <Divider />
            <WhiteButton onClick={() => append({})}><ButtonIcon icon={faCirclePlus} /> Add Additional ERP Group</WhiteButton>
        </div>
    )
}