import { useContext, useEffect, useState } from "react"
import Row from "./Row"
import { ScreenHeader, Table, TableData, TableHeader, TableRow } from "./Table"
import { TabContent } from "./Tabs"


import Statistic from "./Statistic"
import { AdminButton } from "./Buttons"
import { ButtonIcon } from "./Typography"
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons"
import { UncontrolledFormTextInput } from "./FormTextInput"
import { useDispatch, useSelector } from "react-redux"
import { listSatelliteDevices, selectDeviceList, setActiveSatelliteDevice } from "../common/slices/satelliteDevice"
import { selectUser } from "../common/slices/user"
import { formatPhoneNumber } from "react-phone-number-input"
import ModalManageSatelliteDevice from "./ModalManageSatelliteDevice"
import { PrivacyContext } from "../App"


const TabManageSatellite = () => {
    const privacy = useContext(PrivacyContext);
    const [showEditDevice, setShowEditDevice] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const devices = useSelector(selectDeviceList);

    useEffect(() => {
        if (user) {
            dispatch(listSatelliteDevices(user?.companyId))
        }
    }, [dispatch, user])

    const assigned = devices.filter(d => d.assignedTo !== null);

    return (
        <>
            <Row style={{ gap: '3rem', marginBottom: '2rem' }}>
                <Statistic title="Total Devices" data={devices.length} />
                <Statistic title="Available Devices" data={devices.length - assigned.length} />
                <Statistic title="Assigned Devices" data={assigned.length} />
            </Row>
            <ScreenHeader>
                <UncontrolledFormTextInput placeholder="Enter device IMEI, phone number, or user" />
                <AdminButton onClick={() => setShowEditDevice(true)}><ButtonIcon icon={faCirclePlus} /> Add Device</AdminButton>
            </ScreenHeader>

            <Table>
                <thead>
                    <tr>
                        <TableHeader>Device IMEI</TableHeader>
                        <TableHeader>Brand</TableHeader>
                        <TableHeader>Phone Number</TableHeader>
                        <TableHeader>Assigned User</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {
                        devices.map(device => (
                            <TableRow key={device.id} onClick={() => {
                                dispatch(setActiveSatelliteDevice(device))
                                setShowEditDevice(true);
                            }}>
                                <TableData>{device.imei}</TableData>
                                <TableData>{device.brand}</TableData>
                                <TableData>{formatPhoneNumber(device.phoneNumber)}</TableData>
                                <TableData dataPrivacy={privacy}>{device.assignedUser?.firstName} {device.assignedUser?.lastName}</TableData>
                            </TableRow>
                        ))
                    }
                </tbody>
            </Table>
            {showEditDevice && <ModalManageSatelliteDevice onClose={() => setShowEditDevice(false)} />}
        </>

    )
}


export default () => (
    <TabContent tabId={5}>
        <TabManageSatellite />
    </TabContent>
)

