import { generateClient } from "aws-amplify/api";
import { createERPArea } from "../graphql/mutations";

const client = generateClient();

const insertERP = async ({ name, companyId }) => {
    try {
        const {
            data: { createERPArea: newArea },
        } = await client.graphql({
            query: createERPArea,
            variables: {
                input: {
                    name,
                    companyId,
                },
            },
        });
        return newArea;
    } catch (ex) {
        console.log("failed to insert area", ex);
    }
};

export default insertERP;
