import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const H1 = styled.h1`
    font-size: 24px;
    font-weight: 700;
    padding: ${(props) => props.pad ? "0.5rem 0" : "0"}; 
    margin: ${(props) => props.noMargin ? "0" : "0.5rem 0"};
    `

export const H2 = styled.h2`
    font-size: 20px;
    font-weight: 700;
    filter: ${props => props.dataPrivacy ? "blur(0.4rem)" : 'none'};
    padding: ${(props) => props.pad ? "0.5rem 0" : "0"}; 
    margin: ${(props) => props.noMargin ? "0" : "0.5rem 0"};
    `
export const H3 = styled.h3`
    font-size: 16px;
    font-weight: 700;
    padding: ${(props) => props.pad ? "0.5rem 0" : "0"}; 
    margin: ${(props) => props.noMargin ? "0" : "0.5rem 0"};
    color: inherit;
    `

export const P = styled.p`
    font-weight: 400;
    line-height: 25px;
    padding: ${(props) => props.pad ? "0.5rem 0" : "0"}; 
    margin: ${(props) => props.noMargin ? "0" : "0.5rem 0"};
    filter: ${props => props.dataPrivacy ? 'blur(0.4rem)' : 'none'};
    color: inherit; 
`

export const P2 = styled.p`
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    padding: ${(props) => props.pad ? "0.5rem 0" : "0"}; 
    margin: ${(props) => props.noMargin ? "0" : "0.5rem 0"};   
`

export const ButtonIcon = styled(FontAwesomeIcon)`
    margin-right: 1rem;
`

export const Label = styled.label`
    font-weight: 400;
    line-height: 1.4rem;
`