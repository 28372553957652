
import OFF from '../assets/OFF.png';
import SHIFT from '../assets/SHIFT.png';
import SHIFT_ACCIDENT from '../assets/SHIFT_ACCIDENT.png';
import SHIFT_NOT_RESPONDING from '../assets/SHIFT_NOT_RESPONDING.png';
import SHIFT_OK from '../assets/SHIFT_OK.png';
import SHIFT_PANIC from '../assets/SHIFT_PANIC.png';

const statusMap = {
    SHIFT,
    SHIFT_OK,
    SHIFT_PANIC,
    SHIFT_NOT_RESPONDING,
    SHIFT_ACCIDENT,
    OFF
}

const ImageCheckIn = ({ status, ...props }) => {
    return (
        <img
            src={statusMap[status]}
            {...props}
        />
    )
}

export default ImageCheckIn;