import theme from "../common/theme";
import { P, P2 } from "./Typography";

const Statistic = ({ data, title, style }) => (
    <div style={{ flex: 0, margin: '0.25rem 0', ...style }}>
        <P2 style={{ color: theme.palette.fontDark, margin: 0, padding: 0, textWrap: 'nowrap' }}>{title}</P2>
        <h1 style={{ fontSize: '48px', fontWeight: 500, padding: 0, margin: 0 }}>{data}</h1>
    </div>
)

export default Statistic;