import { generateClient } from "aws-amplify/api";
import { sendPushNotification } from "../graphql/mutations";

const client = generateClient();

export default async (userId, title, body) => {
    const { data } = await client.graphql({
        query: sendPushNotification,
        variables: {
            input: {
                userId,
                title,
                body
            }
        }
    })
    return data.sendPushNotification
};
