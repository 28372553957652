import React from "react";
import { BlackButton, BlueButton, ButtonBase, DarkButton, RedButton } from "./Buttons";
import Modal, { Container, Footer, Header } from "./Modal";
import Row from "./Row";
import { H1, P } from "./Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";


const ModalError = ({ onClose, title = "Error", errorMessage, zIndex = 2004, disabled }) => {
    return (
        <Modal show size="33%" containerStyle={{ zIndex }}>
            <Container>

                <Header>
                    <H1>{title}</H1>
                    <ButtonBase onClick={onClose} style={{ marginLeft: 'auto' }} disabled={disabled}><FontAwesomeIcon icon={faTimesCircle} size='2x' /></ButtonBase>
                </Header>

                <P dangerouslySetInnerHTML={{ __html: errorMessage }} />
                <Footer>
                    <RedButton onClick={onClose} disabled={disabled}>Okay</RedButton>
                </Footer>
            </Container>
        </Modal>
    );
};

export default ModalError;
