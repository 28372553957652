const theme = {
    palette: {
        background: "#171931",
        typography: "#fff",
        tableRow: "rgba(16, 18, 36, 1)",
        tableActive: "rgba(7, 8, 16, 1)",
        border: "rgba(41, 44, 84, 1)",
        placeholder: "rgba(129, 129, 129, 1)",
        tabInactive: "rgba(96,96,96,1)",
        font: 'rgba(241,241,241,1)',
        fontDark: 'rgba(187, 187, 187, 1)',
        dark: '#040408',
        green: "#42A776",
        blue: "#171931",
        darkBlue: 'rgba(23, 25, 49, 1)',
        lightBlue: "#4385F3",
        red: "#b53434",
        yellow: "#f2b401"
    }
}

export default theme;