import { generateClient } from "aws-amplify/api";
import { createCompany } from "../graphql/mutations";

const client = generateClient();

const insertCompany = async ({ name, logo, emergencyResponsePhoneNumber }) => {
    try {
        const {
            data: { createCompany: newCompany },
        } = await client.graphql({
            query: createCompany,
            variables: {
                input: {
                    name,
                    logo,
                    emergencyResponsePhoneNumber,
                },
            },
        });
        return newCompany;
    } catch (ex) {
        console.log("failed to insert company", ex);
    }
};

export default insertCompany;
