import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { sendPushNotification } from "../common/slices/user";
import { ButtonBase, RedButton } from "./Buttons";
import FormTextArea from "./FormTextArea";
import FormTextInput from "./FormTextInput";
import Modal, { Container, Footer, Header } from './Modal';
import { H1, P } from "./Typography";
import { useContext } from "react";
import { ErrorContext } from "../App";

const ModalSendPushNotification = ({ userId, onClose }) => {
    const errorContext = useContext(ErrorContext);
    const dispatch = useDispatch();

    const onSaveClick = async (values) => {
        try {
            await dispatch(sendPushNotification(userId, values.title, values.body));
            onClose();
        } catch (ex) {
            errorContext.setError(`Failed to send message to user.<br /><br />Message for Support: <em>${ex.message}</em>`)
        }
    }

    const {
        control,
        setError,
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
    } = useForm({})
    const fieldProps = { control, errors, rules: { required: true } };

    return (
        <Modal
            show
            onClose={onClose}
            size='sm'
        >
            <Container>
                <Header>
                    <H1>Send Push Notification</H1>
                    <ButtonBase onClick={onClose}><FontAwesomeIcon icon={faTimesCircle} size={'xl'} /></ButtonBase>
                </Header>
                <P>Use the form below to send a real-time push notification to this user.</P>
                <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                    <FormTextInput {...fieldProps} name="title" placeholder="Enter a title" label="Title" />
                    <FormTextArea {...fieldProps} name="body" placeholder="Enter a message" label="Message" />
                </form>
                <Footer>
                    <RedButton onClick={handleSubmit(onSaveClick)} disabled={isSubmitting || !isValid}>Send Message</RedButton>
                    <ButtonBase onClick={onClose}>Cancel</ButtonBase>
                </Footer>
            </Container>
        </Modal>
    )
}

export default ModalSendPushNotification