import { ErrorMessage } from '@hookform/error-message';
import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import theme from "../common/theme";
import { P } from "./Typography";

const Input = styled.input`
    flex: 0;
    outline: none;
    padding: 0.5rem 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${theme.palette.red};
    transform: translateY(7px);
    cursor: pointer;
    `;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    `;

const Label = styled.label`   
    line-height: 1.5rem;
    vertical-align: middle;
    cursor: pointer;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const UncontrolledCheckboxInput = forwardRef(({ errors, label, placeholder, containerStyle, options, ...rest }, ref) => {
    return (
        <Container style={containerStyle}>
            <Label>{label}</Label>
            <Label>
                <Input
                    ref={ref}
                    type='checkbox'
                    {...rest}
                    checked={rest.value || false}
                />
                <span style={{ marginLeft: '1rem' }}>{placeholder}</span>
            </Label>
            <ErrorMessage
                errors={errors}
                name={rest.name}
                render={({ message }) => <P style={{ color: theme.palette.red }}>{message}</P>}
            />
        </Container>
    )
})

export default ({ name, control, rules, ...rest }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { ...fieldProps } }) => (
                <UncontrolledCheckboxInput {...fieldProps} {...rest} />
            )}
        />
    );
};
