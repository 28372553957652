import { generateClient } from "aws-amplify/api";
import { getShiftReports } from "../common/graphql/customQueries";

const client = generateClient();

export default async ({ startDate, endDate, companyId, recipients = [], deliveryType = "GRAPHQL" }) => {
    try {
        const {
            data: { getShiftReports: report },
        } = await client.graphql({
            query: getShiftReports,
            variables: {
                input: {
                    deliveryType,
                    startDate,
                    endDate,
                    companyId,
                    recipients,
                },
            },
        });
        return report;
    } catch (ex) {
        console.warn("get report failed", ex);
    }
};
