import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { roles } from "../common/constants"
import { fetchCompany, listCompanies, selectActiveCompany } from '../common/slices/company'
import { fetchCompanySchedules } from '../common/slices/schedule'
import { selectUser } from '../common/slices/user'
import theme from '../common/theme'
import { EmptyTab, Tab, TabContainer, TabsContainer } from "./Tabs"
import { H1 } from './Typography'
import { useEffect } from 'react'
import TabBillingPayments from './TabBillingPayments'
import TabArchiveReport from './TabArchiveReport'

const ScreenManageCompany = () => {
    return (
        <>
            <TabContainer style={{ padding: '1rem' }}>
                <H1 style={{ fontWeight: 700, fontSize: '35px', marginBottom: '2rem' }}>Settings</H1>

                <TabsContainer>
                    <Tab tabId={0}>Archive Report</Tab>
                    <Tab tabId={1}>Billing & Payments</Tab>
                    <EmptyTab />
                </TabsContainer>

                <TabArchiveReport />
                <TabBillingPayments />

            </TabContainer>
        </>
    )
}

export default ScreenManageCompany;
