import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import service from "../service";

import { useForm } from "react-hook-form";
import { selectActiveUser } from "../common/slices/user";
import { ButtonBase, RedButton } from "./Buttons";
import FormCheckbox from "./FormCheckbox";
import FormTextInput from "./FormTextInput";
import Modal, { Container, Footer, Header } from './Modal';
import { H1 } from "./Typography";

const ModalSetPassword = ({ show, onClose }) => {
    const activeUser = useSelector(selectActiveUser);

    const onSaveClick = async (values) => {
        try {
            await service.setUserPassword(values);
            onClose();
        } catch (ex) {
            setError("password", { message: ex.message })
        }
    }

    const {
        control,
        setError,
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
    } = useForm({
        defaultValues: {
            username: activeUser?.email,
        }
    })
    const fieldProps = { control, errors };

    return (
        <Modal
            show={show}
            onClose={onClose}
            size='sm'
        >
            <Container>
                <Header>
                    <H1>Set User Password</H1>
                    <ButtonBase onClick={onClose}><FontAwesomeIcon icon={faTimesCircle} size={'xl'} /></ButtonBase>
                </Header>
                <form onSubmit={handleSubmit}>
                    <FormTextInput {...fieldProps} name="password" placeholder="New Password" label="New Password" type="password" rules={{ required: true }} />
                    <FormCheckbox {...fieldProps} name="permanent" placeholder="Permanently set password?" type="checkbox" />
                    <p>If you do not permanently set the password, the user will be emailed this password and requested that they change it on their next login.</p>
                </form>
                <Footer>
                    <RedButton onClick={handleSubmit(onSaveClick)} disabled={isSubmitting || !isValid}>Set Password</RedButton>
                    <ButtonBase onClick={onClose}>Cancel</ButtonBase>
                </Footer>
            </Container>
        </Modal>
    )
}

export default ModalSetPassword