import { generateClient } from "aws-amplify/api";
import { checkInsByCompanyId } from "../graphql/customQueries";

const client = generateClient();

export default async (companyId) => {
    try {
        const {
            data: {
                checkInsByCompanyId: { items: checkIns },
            },
        } = await client.graphql({
            query: checkInsByCompanyId,
            variables: {
                companyId,
                limit: 99999,
            },
        });
        return checkIns;
    } catch (ex) {
        console.warn("get checkins failed", companyId, ex);
    }
};
