import { searchUsers } from "../common/graphql/queries";
import QueryBuilder from '../common/service/QueryBuilder';
import FormSelectInput from './FormSelectInput';

const search = new QueryBuilder(searchUsers, "searchUsers", null);
const FormSelectUser = ({ role, companyId, ...props }) => {
    const loadOptions = async (input) => {
        if (role === "OPERATOR") {
            search.setFilter({ firstName: { wildcard: `*${input}*` } })
        } else {
            search.setFilter({
                companyId: { eq: companyId },
                firstName: { wildcard: `*${input}*` }
            })
        }
        search.setParams({ sort: [{ field: 'firstName', direction: 'asc' }] })
        const { items } = await search.execute();
        return items.map(i => ({ value: i.email, label: `${i.firstName} ${i.lastName} (${i.email})` }))
    }

    return (
        <FormSelectInput name="userId" placeholder="Select a User" label="User" loadOptions={loadOptions} {...props} />
    )
}

export default FormSelectUser;