import { createSelector, createSlice } from "@reduxjs/toolkit";
import { generateClient } from "aws-amplify/api";
import { roles } from "../constants";
import * as queries from "../graphql/customQueries";
import { createPortalSession, createSatelliteDevice, getMeterSummary, getPendingInvoice, updateContacts, updateSatelliteDevice, deleteSatelliteDevice } from "../graphql/mutations";
import service from "../service";
import QueryBuilder from "../service/QueryBuilder";
import { reset } from "./globalActions";

const client = generateClient();

const listSatelliteDevicesQuery = new QueryBuilder(queries.satelliteDevicesByCompanyId, "satelliteDevicesByCompanyId", 13)

const initialState = {
    loading: false,
    active: null,
    list: [],
    listPageTokens: [],
    invoice: null,
    meterSummary: []
};

const satelliteDevice = createSlice({
    name: "satelliteDevice",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(reset, () => initialState);
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setActiveSatelliteDevice: (state, action) => {
            state.active = action.payload;
        },
        setList: (state, action) => {
            state.list = action.payload;
        },
        onUpdateSatelliteDevice: (state, action) => {
            let idx = state.list.findIndex((c) => c.id === action.payload.id);
            if (idx >= 0) {
                state.list[idx] = action.payload;
            } else {
                state.list.push(action.payload);
            }

            if (state.active?.id === action.payload.id) {
                state.active = action.payload
            }
        },
        onDeleteSatelliteDevice: (state, action) => {
            let idx = state.list.findIndex((c) => c.id === action.payload.id);
            state.list = [...state.list.slice(0, idx), ...state.list.slice(idx + 1)];
        },
        onInsertSatelliteDevice: (state, action) => {
            state.list.push(action.payload);
        },
    },
});

export const { setLoading, setList, setActiveSatelliteDevice, onInsertSatelliteDevice, onUpdateSatelliteDevice, onDeleteSatelliteDevice } = satelliteDevice.actions;
export default satelliteDevice.reducer;

export const selectState = (state) => state.satelliteDevice;
export const selectDeviceList = createSelector(selectState, (state) => state.list);
export const selectActiveDevice = createSelector(selectState, (state) => state.active);

export const listSatelliteDevices = (companyId, role, pageSize = 13) => async (dispatch) => {
    listSatelliteDevicesQuery.pageSize = pageSize;
    listSatelliteDevicesQuery.setParams({ companyId });
    let { items } = await listSatelliteDevicesQuery.execute();
    dispatch(setList(items));
    return items;
};

export const nextPage = (companyId, role) => async (dispatch) => {
    let companies = await listSatelliteDevicesQuery.nextPage();
    dispatch(setList(companies.items));
}

export const prevPage = (companyId, role) => async (dispatch) => {
    let companies = await listSatelliteDevicesQuery.prevPage();
    dispatch(setList(companies.items));
}

// export const searchCompanies = (search) => async (dispatch) => {
//     let companies = [];
//     listSatelliteDevicesQuery.setFilter({ or: [{ name: { contains: search } }] })
//     listSatelliteDevicesQuery.pageSize = null;
//     companies = await listSatelliteDevicesQuery.execute();
//     dispatch(setList(companies.items));
// }

export const insertSatelliteDevice = (device) => async (dispatch) => {
    const { data } = await client.graphql({
        query: createSatelliteDevice,
        variables: {
            input: {
                ...device,
            }
        }
    });
    dispatch(onInsertSatelliteDevice(data.createSatelliteDevice));
};

export const modifySatelliteDevice = (device) => async (dispatch) => {
    const { data } = await client.graphql({
        query: updateSatelliteDevice,
        variables: {
            input: {
                ...device,
            }
        }
    });
    dispatch(onUpdateSatelliteDevice(data.updateSatelliteDevice));
};

export const removeSatelliteDevice = (device) => async (dispatch) => {
    const { data } = await client.graphql({
        query: deleteSatelliteDevice,
        variables: {
            input: {
                ...device,
            }
        }
    });
    dispatch(onDeleteSatelliteDevice(data.deleteSatelliteDevice));
};

// export const updateCompany = (company) => async (dispatch) => {
//     const id = await service.updateCompany(company);
//     const updatedCompany = await service.getCompany(id);
//     dispatch(onUpdateCompany(updatedCompany));
// };

// export const deleteCompany = (company) => async (dispatch) => {
//     const success = await service.deleteCompany(company);
//     if (success) { dispatch(onDeleteCompany(company)) }
//     return success;
// };

// export const deleteERP = (erpArea) => async (dispatch) => {
//     await service.deleteERP(erpArea);
// };

// export const deleteContact = (contact) => async (dispatch) => {
//     await service.deleteContact(contact);
// };

// export const updateERPContacts = (contacts) => async (dispatch) => {
//     try {
//         const response = await client.graphql({
//             query: updateContacts,
//             variables: {
//                 input: {
//                     type: "ERP",
//                     contacts,
//                 }
//             }
//         })

//         console.log(response);
//     } catch (ex) {
//         console.log("Failed to uypdate ERP contacts", ex);
//     }
// }

// export const fetchPortalSession = (customerId, returnURL) => async (dispatch) => {
//     try {
//         const response = await client.graphql({
//             query: createPortalSession,
//             variables: { input: { customerId, returnURL } }
//         })
//         return response.data.createPortalSession;
//     } catch (ex) {
//         console.log("Failed to get company");
//     }
// }
