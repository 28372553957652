import { getCurrentUser } from "aws-amplify/auth"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export default () => {
    const nav = useNavigate();

    useEffect(() => {
        try {
            getCurrentUser()
                .then(user => {
                    if (!user) {
                        nav('/auth/login');
                    }
                }, () => {
                    console.log('user is not authenticated');
                    nav('/auth/login');
                })
        } catch (ex) {
            console.log('Failed to get user', ex);
            nav('/auth/login');
        }
    }, [])
}