import { generateClient } from "aws-amplify/api";
import { createEmergencyContact } from "../graphql/mutations";

const client = generateClient();

const insertContact = async ({ id, erpAreaId, name, notifyBySMS = true, notifyByEmail = true, email, phone, priority, notificationTypes = ["SHIFT_ACCIDENT", "SHIFT_NOT_RESPONDING", "SHIFT_PANIC", "JOURNEY_ACCIDENT", "JOURNEY_NOT_RESPONDING", "JOURNEY_PANIC"] }) => {
    try {
        const {
            data: { createEmergencyContact: newEntity },
        } = await client.graphql({
            query: createEmergencyContact,
            variables: {
                input: {
                    id,
                    name,
                    email,
                    priority,
                    erpAreaId,
                    notifyBySMS,
                    notificationTypes,
                    notifyByEmail,
                    phone,
                },
            },
        });
        return newEntity;
    } catch (ex) {
        console.log("failed to insert contact", ex);
    }
};

export default insertContact;
