import { generateClient } from "aws-amplify/api";
import { listUserSettings } from "../graphql/queries";

const client = generateClient();

export default async (email, pageSize = 10, nextToken) => {
    try {
        const {
            data: { listUserSettings: { items: settings }},
        } = await client.graphql({
            query: listUserSettings,
            variables: {
                email,
                limit: pageSize,
                nextToken,
            },
        });
        return settings;
    } catch (e) {
        console.error("failed to list users", e);
        return false;
    }
};
