import { generateClient } from "aws-amplify/api";
import { usersByCompanyIdAndStatus } from "../graphql/queries";

const client = generateClient();

export default async (companyId, pageSize = 13, nextToken) => {
    try {
        const {
            data: { usersByCompanyIdAndStatus: users },
        } = await client.graphql({
            query: usersByCompanyIdAndStatus,
            variables: {
                companyId,
                limit: pageSize,
                nextToken
            },
        });
        return users;
    } catch (e) {
        console.error("failed to list users", e);
        return false;
    }
};
