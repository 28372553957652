import { createSelector, createSlice } from "@reduxjs/toolkit";
import { reset } from "./globalActions";
import { generateClient } from "aws-amplify/api";
import * as queries from '../graphql/customQueries';
import * as mutations from '../graphql/mutations';

const client = generateClient();

const moduleName = 'schedule';

const initialState = {
    schedules: []
};

const scheduleSlice = createSlice({
    name: moduleName,
    initialState,
    extraReducers: (builder) => {
        builder.addCase(reset, (state) => initialState)
    },
    reducers: {
        setSchedules: (state, action) => {
            state.schedules = action.payload
        },
        addSchedule: (state, action) => {
            state.schedules.push(action.payload);
        },
        onUpdateShiftPattern: (state, action) => {
            let schedule = state.schedules.find(s => s.id === action.payload.scheduleId)
            if (!schedule.recurringShiftPatterns?.items) {
                schedule.recurringShiftPatterns.items = [];
            }

            const idx = schedule.recurringShiftPatterns.items.findIndex(s => s.id === action.payload.id);
            if (idx >= 0) {
                schedule.recurringShiftPatterns.items[idx] = action.payload;
            } else {
                schedule.recurringShiftPatterns?.items.push(action.payload);
            }
        },
        onUpdateIndividualShift: (state, action) => {
            let schedule = state.schedules.find(s => s.id === action.payload.scheduleId)
            if (!schedule.individualShifts?.items) {
                schedule.individualShifts.items = [];
            }

            const idx = schedule.individualShifts.items.findIndex(s => s.id === action.payload.id);
            if (idx >= 0) {
                schedule.individualShifts.items[idx] = action.payload;
            } else {
                schedule.individualShifts?.items.push(action.payload);
            }
        },
        onDeleteIndividualShift: (state, action) => {
            let schedule = state.schedules.find(s => s.id === action.payload.scheduleId)
            if (schedule?.individualShifts?.items?.length) {
                const idx = schedule.individualShifts.items.findIndex(s => s.id === action.payload.id);
                schedule.individualShifts.items = [...schedule.individualShifts.items.slice(0, idx), ...schedule.individualShifts.items.slice(idx + 1)]
            }
        },
        onDeleteRecurringShiftPattern: (state, action) => {
            let schedule = state.schedules.find(s => s.id === action.payload.scheduleId)
            if (schedule?.recurringShiftPatterns?.items?.length) {
                const idx = schedule.recurringShiftPatterns.items.findIndex(s => s.id === action.payload.id);
                schedule.recurringShiftPatterns.items = [...schedule.recurringShiftPatterns.items.slice(0, idx), ...schedule.recurringShiftPatterns.items.slice(idx + 1)]
            }

        }
    },
});

export const {
    setSchedules,
    addSchedule,
    onUpdateShiftPattern,
    onUpdateIndividualShift,
    onDeleteIndividualShift,
    onDeleteRecurringShiftPattern
} = scheduleSlice.actions;
export default scheduleSlice.reducer;

export const selectState = (state) => state[moduleName];
export const selectSchedules = createSelector(selectState, state => state.schedules);

export const createShiftSchedule = ({ email, name, description, companyId }) => async (dispatch) => {
    try {
        const response = await client.graphql({
            query: mutations.createShiftSchedule,
            variables: {
                input: {
                    email,
                    name,
                    description,
                    companyId,
                }
            }
        })
        dispatch(addSchedule(response.data.createShiftSchedule));
        return true;
    } catch (ex) {
        console.log("failed to create schedule", ex);
    }
}

export const createRecurringShiftPattern = ({ email, name, description, scheduleId, shiftPattern, startTime, endTime, startDate, endDate }) => async (dispatch) => {
    try {
        const response = await client.graphql({
            query: mutations.createRecurringShiftPattern,
            variables: {
                input: {
                    email,
                    name,
                    description,
                    scheduleId,
                    shiftPattern,
                    startTime,
                    endTime,
                    startDate,
                    endDate
                }
            }
        })
        dispatch(onUpdateShiftPattern(response.data.createRecurringShiftPattern));
        return true;
    } catch (ex) {
        console.log("failed to create shift pattern", ex);
    }
}

export const updateRecurringShiftPattern = ({ id, email, name, description, scheduleId, shiftPattern, startTime, endTime, startDate, endDate }) => async (dispatch) => {
    try {
        const response = await client.graphql({
            query: mutations.updateRecurringShiftPattern,
            variables: {
                input: {
                    id,
                    email,
                    name,
                    description,
                    scheduleId,
                    shiftPattern,
                    startTime,
                    endTime,
                    startDate,
                    endDate
                }
            }
        })
        dispatch(onUpdateShiftPattern(response.data.updateRecurringShiftPattern));
        return true;
    } catch (ex) {
        console.log("failed to update shift pattern", ex);
    }
}

export const createIndividualShift = ({ name, description, email, scheduleId, shiftDate, startTime, endTime }) => async (dispatch) => {
    try {
        const response = await client.graphql({
            query: mutations.createIndividualShift,
            variables: {
                input: {
                    email,
                    scheduleId,
                    name,
                    description,
                    shiftDate,
                    startTime,
                    endTime,
                }
            }
        })
        dispatch(onUpdateIndividualShift(response.data.createIndividualShift));
        return true;
    } catch (ex) {
        console.log("failed to create individual shift", ex);
    }
}

export const updateIndividualShift = ({ id, name, description, email, scheduleId, shiftDate, startTime, endTime }) => async (dispatch) => {
    try {
        const response = await client.graphql({
            query: mutations.updateIndividualShift,
            variables: {
                input: {
                    id,
                    email,
                    scheduleId,
                    name,
                    description,
                    shiftDate,
                    startTime,
                    endTime,
                }
            }
        })
        dispatch(onUpdateIndividualShift(response.data.updateIndividualShift));
        return true;
    } catch (ex) {
        console.log("failed to update individual shift", ex);
    }
}

export const deleteIndividualShift = ({ id, shiftDate }) => async (dispatch) => {
    try {
        const response = await client.graphql({
            query: mutations.deleteIndividualShift,
            variables: {
                input: {
                    id,
                    shiftDate
                }
            }
        })
        dispatch(onDeleteIndividualShift(response.data.deleteIndividualShift));
        return true;
    } catch (ex) {
        console.log("failed to delete individual shift", ex);
    }
}

export const deleteRecurringShiftPattern = ({ id }) => async (dispatch) => {
    try {
        const response = await client.graphql({
            query: mutations.deleteRecurringShiftPattern,
            variables: {
                input: {
                    id,
                }
            }
        })
        dispatch(onDeleteRecurringShiftPattern(response.data.deleteRecurringShiftPattern));
        return true;
    } catch (ex) {
        console.log("failed to delete recurring shift pattern", ex);
    }
}

export const fetchUserSchedules = (userId) => async (dispatch) => {
    try {
        const response = await client.graphql({
            query: queries.shiftScheduleByEmail,
            variables: {
                email: userId
            }
        })
        dispatch(setSchedules(response?.data?.shiftScheduleByEmail?.items))
        return true;
    } catch (ex) {
        console.log('failed to get user schedules', ex);
    }
}

export const fetchCompanySchedules = (companyId) => async (dispatch) => {
    try {
        const response = await client.graphql({
            query: queries.shiftSchedulesByCompanyId,
            variables: {
                companyId
            }
        })
        dispatch(setSchedules(response?.data?.shiftSchedulesByCompanyId?.items))
        return true;
    } catch (ex) {
        console.log('failed to get company schedules', ex);
    }
}
