import { faPlusCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import service from "../service";

import { useForm } from "react-hook-form";
import { selectActiveUser } from "../common/slices/user";
import theme from '../common/theme';
import { BlueButton, ButtonBase, RedButton } from "./Buttons";
import FormCheckbox from "./FormCheckbox";
import FormTextInput from "./FormTextInput";
import Modal, { Container, Footer, Header } from './Modal';
import { H1 } from "./Typography";
import FormDatePicker from "./FormDatePicker";
import FormSelectShiftType from "./FormSelectShiftType";
import moment from 'moment';
import Row from "./Row";
import FormSelectShiftPattern from "./FormSelectShiftPattern";
import FormSelectSchedule from "./FormSelectSchedule";
import { createIndividualShift, createRecurringShiftPattern, createShiftSchedule, deleteIndividualShift, deleteRecurringShiftPattern, updateIndividualShift, updateRecurringShiftPattern } from "../common/slices/schedule";
import { useState } from "react";
import ModalAddSchedule from "./ModalAddSchedule";
import FormTextArea from "./FormTextArea";

const utcOffset = moment().utcOffset();

const marshall = (event) => ({
    id: event.id,
    name: event.name,
    description: event.description,
    scheduleId: !event.scheduleId ? '' : event.scheduleId,
    shiftType: event.__typename === "RecurringShiftPattern" ? 'RECURRING' : 'INDIVIDUAL',
    shiftPattern: !event.shiftPattern ? [{ value: moment(event.start).format('dddd').toUpperCase(), label: moment(event.start).format('dddd') }] : event.shiftPattern.map(sp => ({ value: sp, label: sp.toLowerCase() })),
    shiftDate: !event.startDate ? moment(event.start) : moment(event.startDate),
    startTime: !event.startTime ? moment(event.start) : moment(event.startTime),
    endTime: !event.endTime ? moment(event.end) : moment(event.endTime),
    startDate: !event.startDate ? moment(event.start) : moment(event.startDate),
    endDate: !event.endDate ? moment(event.end) : moment(event.endDate),
});
const unmarshall = (event) => ({
    id: event.id,
    name: event.name,
    description: event.description,
    shiftType: event.shiftType,
    scheduleId: event.scheduleId,
    startDate: moment(event.startDate).format('yyyy-MM-DD'),
    endDate: moment(event.endDate).format('yyyy-MM-DD'),
    shiftDate: moment(event.shiftDate).format('yyyy-MM-DD'),
    startTime: moment(event.startTime).toISOString(),
    endTime: moment(event.endTime).toISOString(),
    shiftPattern: event.shiftPattern?.map(sp => sp.value)
})

const ModalScheduleEventDetails = ({ show, onClose, slotDetails }) => {
    const dispatch = useDispatch();
    const activeUser = useSelector(selectActiveUser);
    const [showNewSchedule, setShowNewSchedule] = useState(false);

    const {
        control,
        setError,
        formState: { errors, isSubmitting, isValid },
        handleSubmit,
        watch,
    } = useForm({
        values: marshall(slotDetails),
    })

    const fieldProps = { control, errors, rules: { required: true } };

    const shiftType = watch('shiftType');

    const onSaveClick = async (values) => {
        const shift = unmarshall(values);
        switch (values.shiftType) {
            case "INDIVIDUAL":
                if (!shift.id) {
                    dispatch(createIndividualShift({ email: activeUser.email, ...shift }))
                } else {
                    dispatch(updateIndividualShift({ id: shift.id, ...shift }))
                }
                break;
            case "RECURRING":
                if (!shift.id) {
                    dispatch(createRecurringShiftPattern({ email: activeUser.email, ...shift }))
                } else {
                    dispatch(updateRecurringShiftPattern({ email: activeUser.email, ...shift }))
                }
                break;
        }
        onClose();
    }

    const onDeleteClick = async (values) => {
        const shift = unmarshall(values);

        switch (shift.shiftType) {
            case "INDIVIDUAL":
                await dispatch(deleteIndividualShift(shift));
                break;
            case "RECURRING":
                await dispatch(deleteRecurringShiftPattern(shift));
                break;
        }
        onClose();
    }

    return (
        <Modal
            show={show}
            onClose={onClose}
            size='sm'
        >
            <Container>
                <Header>
                    <H1>{slotDetails?.id ? 'Edit' : 'Create'} Shift</H1>
                    <ButtonBase onClick={onClose}><FontAwesomeIcon icon={faTimesCircle} size={'xl'} /></ButtonBase>
                </Header>
                {!slotDetails?.id && <FormSelectSchedule {...fieldProps} userId={activeUser.email} />}
                <FormTextInput {...fieldProps} name="name" label="Name" placeholder="Enter shift name" rules={{ required: false }} />
                <FormTextArea {...fieldProps} name="description" label="Description" placeholder="Enter shift description (optional)" rules={{ required: false }} />

                <FormSelectShiftType {...fieldProps} disabled={slotDetails?.id} />

                {
                    shiftType === "INDIVIDUAL" &&
                    <>
                        <FormDatePicker {...fieldProps} name="shiftDate" placeholder="Enter shift date" label="Shift Date" disabled={slotDetails?.id} />
                        <Row>
                            <FormDatePicker showTimeSelect showTimeSelectOnly {...fieldProps} name="startTime" placeholder="Enter shift start time" label="Start Time" dateFormat={"HH:mm"} />
                            <FormDatePicker showTimeSelect showTimeSelectOnly {...fieldProps} name="endTime" placeholder="Enter shift end time" label="End Time" dateFormat={"HH:mm"} />
                        </Row>
                    </>
                }

                {
                    shiftType === "RECURRING" &&
                    <>

                        <Row>
                            <FormDatePicker {...fieldProps} name="startDate" placeholder="Enter shift start date" label="Start Date" dateFormat={"yyyy-MM-dd"} />
                            <FormDatePicker {...fieldProps} name="endDate" placeholder="Enter shift end date" label="End Date" dateFormat={"yyyy-MM-dd"} />
                        </Row>

                        <FormSelectShiftPattern {...fieldProps} name="shiftPattern" placeholder="Select Days" label="Shift Days" />
                        <Row>
                            <FormDatePicker showTimeSelect showTimeSelectOnly {...fieldProps} name="startTime" placeholder="Enter shift start time" label="Start Time" dateFormat={"HH:mm"} />
                            <FormDatePicker showTimeSelect showTimeSelectOnly {...fieldProps} name="endTime" placeholder="Enter shift end time" label="End Time" dateFormat={"HH:mm"} />
                        </Row>
                    </>
                }
                <Footer>
                    <BlueButton onClick={handleSubmit(onSaveClick)} disabled={isSubmitting || !isValid} style={{ marginRight: 'auto' }}>Save</BlueButton>
                    {slotDetails?.id && <RedButton onClick={handleSubmit(onDeleteClick)} disabled={isSubmitting}>Delete</RedButton>}
                    <ButtonBase onClick={onClose}>Cancel</ButtonBase>
                </Footer>
            </Container>
            {showNewSchedule && <ModalAddSchedule onClose={() => setShowNewSchedule(false)} />}
        </Modal>
    )
}

export default ModalScheduleEventDetails