import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import friendlyStatus from "../common/utils/friendlyStatus"
import ImageCheckIn from "./ImageCheckIn"
import { P } from "./Typography"
import moment from "moment"
import isNotification from "../common/utils/isNotification"
import theme from "../common/theme"
import { faCircle } from "@fortawesome/free-solid-svg-icons"
import getUpdateDelay from "../service/getUpdateDelay"
import Row from "./Row"
import { useEffect, useRef, useState } from "react"

export const Status = ({ status, style }) => (
    <span style={{
        background: '#000',
        color: 'white',
        display: 'flex',
        flex: 0,
        padding: '0.5rem 1rem',
        textWrap: 'nowrap',
        gap: '0.5rem',
        fontWeight: 700,
        alignItems: 'center',
        ...style,
    }}><ImageCheckIn status={status} /> {friendlyStatus(status)}</span>
)
export const StatusLastUpdate = ({ checkIn, style, title = "Location Updated" }) => {
    return (
        <span style={{
            background: isNotification(checkIn?.status) ? theme.palette.red : 'white',
            color: isNotification(checkIn?.status) ? 'white' : 'black',
            display: 'flex',
            flex: 1,
            padding: '0.5rem 1rem',
            alignItems: 'center',
            gap: '0.5rem',
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            ...style,
        }}>
            <FontAwesomeIcon icon={faCircle} size="sm" color={checkIn.status !== "OFF" ? moment(checkIn.timestamp).add(getUpdateDelay(checkIn), 's').isAfter(moment()) ? theme.palette.green : theme.palette.yellow : theme.palette.border} />
            <P noMargin style={{
                fontSize: 'inherit', textWrap: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
            }}>
                {title && <><b>{title}</b>&nbsp;-&nbsp;</>}
                {moment(checkIn.timestamp).fromNow()}
            </P>
        </span>
    )
}

export const ShiftStatus = ({ checkIn }) => {
    const animationFrame = useRef();
    const [r, forceRender] = useState(0);

    useEffect(() => {
        animationFrame.current = setInterval(process, 1000);
        return () => clearInterval(animationFrame.current);
    }, [])

    const process = () => {
        forceRender(b => b + 1);
    }

    return (
        <Row style={{ flex: 0, gap: 0, marginBottom: '1rem' }} renderItr={r}>
            <Status status={checkIn?.status} />
            <StatusLastUpdate checkIn={checkIn} />
        </Row>
    )
}