import { generateClient } from "aws-amplify/api";
import { listCompanies } from "../graphql/customQueries";

const client = generateClient();

export default async () => {
    try {
        const { data: { listCompanies: companies }} = await client.graphql({
            query: listCompanies,
            variables: {
                limit: 99,
            },
        });
        return companies;
    } catch (e) {
        console.error("failed to list companies", e);
        return false;
    }
};
