import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';
import theme from '../common/theme';
import { Label } from './Typography';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const UncontrolledFormPhoneNumber = forwardRef(({ input, label, disabled, ...props }, ref) => (
    <Container>
        <Label>{label}</Label>
        <PhoneInput
            ref={ref}
            style={{
                outline: 'none',
            }}
            {...input}
            disabled={disabled}
            defaultCountry='CA'
            numberInputProps={{
                style: {
                    border: 'none',
                    padding: '1rem',
                    outline: 'none',
                    fontFamily: 'inherit',
                    background: disabled ? theme.palette.tableActive : theme.palette.tableRow,
                    color: disabled ? theme.palette.placeholder : theme.palette.font,
                    "&::placeholder": {
                        color: 'red'
                    }
                }
            }}
            {...props}
        />
    </Container>
))

export default ({ name, control, rules, ...rest }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                ...rules,
            }}
            render={({ field: { ...fieldProps } }) => (
                <UncontrolledFormPhoneNumber {...fieldProps} {...rest} />
            )}
        />
    );
};