import FormTextInput from "./FormTextInput";

const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$|^$/i;


export default (props) => {
    return (
        <FormTextInput {...props} rules={{
            ...props.rules,
            pattern: {
                value: re,
                message: "Invalid email"
            },
        }} />
    );
};
