import { generateClient } from "aws-amplify/api";
import { customDeleteUser } from "../graphql/mutations";

const client = generateClient();

export default async ({ email }) => {
    try {
        await client.graphql({
            query: customDeleteUser,
            variables: {
                input: {
                    email,
                },
            },
        });
    } catch (ex) {
        console.error("Failed to delete user", ex);
    }
};
