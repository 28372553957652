import { generateClient } from "aws-amplify/api";
import { listUsers } from "../graphql/queries";

const client = generateClient();

export default async (pageSize = 13, nextToken) => {
    try {
        const {
            data: { listUsers: users },
        } = await client.graphql({
            query: listUsers,
            variables: {
                limit: pageSize,
                nextToken,
            },
        });
        return users;
    } catch (e) {
        console.error("failed to list users", e);
        return false;
    }
};
