import {
    GoogleMap, useJsApiLoader
} from "@react-google-maps/api";
import { cloneElement, forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectSettings } from "../slices/app";

const Map = forwardRef(({ children,
    center = { lat: 51.03322875980907, lng: -114.042510838868 },
    zoom = 10,
    options = {
        disableDefaultUI: true,
        // mapTypeId: 'satellite',
    },
    ...props
}, ref) => {
    const settings = useSelector(selectSettings);
    const [map, setMap] = useState(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDEZpjJmwd1NXHWCbE4WGOcEhyQX_1sg00"
    })

    if (!isLoaded) {
        return null;
    }

    return (
        <GoogleMap
            ref={ref}
            {...props}
            mapContainerStyle={{
                flex: 1,
                minHeight: '500px',
                ...props.mapContainerStyle,
            }}
            onLoad={(map) => setMap(map)}
            options={{
                styles: settings.mapStyles,
                ...options,
            }}
            zoom={zoom}
            center={center}
        >
            {
                Array.isArray(children) ?
                    children.map(child => cloneElement(child, { map, ...props }))
                    :
                    cloneElement(children, { map, ...props })
            }
        </GoogleMap >
    )
});

export default Map;