import Modal from "./Modal"
import { H2 } from "./Typography"

const ModalLoading = () => {
    return (
        <Modal show size="sm" containerStyle={{ zIndex: 1005 }}>
            <div style={{ padding: '3rem' }}>
                <H2>Loading...</H2>
                Generating your location archive map... This may take a moment. Thanks for your patience!
            </div>
        </Modal>
    )
}

export default ModalLoading;