import { ErrorMessage } from '@hookform/error-message';
import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import Select from 'react-select';
import styled from "styled-components";
import theme from "../common/theme";
import { Label, P } from "./Typography";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    text-wrap: nowrap;
`

const StyledSelect = styled(Select)`
    flex: 1;
`

export const UncontrolledSelectInput = forwardRef(({ errors, label, containerStyle, ...rest }, ref) => {
    return (
        <Container style={containerStyle}>
            <Label>{label}</Label>
            <StyledSelect
                menuPortalTarget={document.body}
                isMulti
                ref={ref}
                {...rest}
                styles={{
                    control: (styles) => ({ ...styles, padding: '0.25rem', background: theme.palette.tableRow, border: 'none' }),
                    valueContainer: (styles) => ({ ...styles, maxHeight: '2rem', gap: '0.25rem' }),
                    menuPortal: (styles) => ({ ...styles, zIndex: 1200, borderRadius: 0 }),
                    menu: (styles) => ({ ...styles, background: theme.palette.tableRow, border: `1px solid ${theme.palette.lightBlue}` }),
                    option: (styles) => ({
                        ...styles,
                        color: theme.palette.font,
                        background: theme.palette.tableRow,
                        "&:hover": {
                            background: theme.palette.tableActive,
                        }
                    }),
                    multiValueRemove: (styles) => ({
                        ...styles,
                        background: 'rgba(96, 96, 96, 1)',
                        color: theme.palette.tableRow,
                        borderRadius: 0,
                        cursor: 'pointer',

                        "&:hover": {
                            background: theme.palette.tableActive,
                            color: 'inherit',
                        }
                    }),
                    multiValueLabel: (styles) => ({
                        ...styles,
                        padding: '0.4rem',
                        whiteSpace: 'nowrap', // Prevent the text from wrapping
                        overflow: 'hidden',
                        textOverflow: 'ellipsis', // Truncate long text })
                        borderRadius: 0,
                        background: 'rgba(96, 96, 96, 1)',
                        color: theme.palette.font,
                    })
                }}
            />
            <ErrorMessage
                errors={errors}
                name={rest.name}
                render={({ message }) => <P style={{ color: theme.palette.red }}>{message}</P>}
            />
        </Container>
    )
})

export default ({ name, control, rules, ...rest }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { ...fieldProps } }) => (
                <UncontrolledSelectInput {...fieldProps} {...rest} />
            )}
        />
    );
};
