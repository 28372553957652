import { ErrorMessage } from '@hookform/error-message';
import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import theme from "../common/theme";
import { Label, P } from "./Typography";

const Input = styled.input`
    flex: 1;
    outline: none;
    padding: 1rem;
    border: none;
    font-family: inherit;
    background: ${props => props.disabled ? theme.palette.tableActive : theme.palette.tableRow};
    color: ${props => props.disabled ? theme.palette.placeholder : theme.palette.font};

    &::placeholder {
        font-family: inherit;
        color: ${theme.palette.placeholder};
    }
`

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

export const UncontrolledFormTextInput = forwardRef(({ errors, label, containerStyle, ...rest }, ref) => {
    return (
        <Container style={containerStyle}>
            <Label>{label}</Label>
            <Input
                ref={ref}
                {...rest}
            />
            {errors && <ErrorMessage
                errors={errors}
                name={rest.name}
                render={({ message }) => <P noMargin style={{ color: theme.palette.red }}>{message}</P>}
            />}
        </Container>
    )
})

export default ({ name, control, rules, ...rest }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { ...fieldProps } }) => (
                <UncontrolledFormTextInput {...fieldProps} {...rest} />
            )}
        />
    );
};
