import { generateClient } from "aws-amplify/api";
import { updateERPArea } from "../graphql/mutations";
import insertERP from "./insertERP";
import updateContact from "./updateContact";

const client = generateClient();

export default async ({ id, name, companyId, emergencyContacts }) => {
    try {
        let erp;
        if (id) {
            const { data } = await client.graphql({
                query: updateERPArea,
                variables: {
                    input: {
                        id,
                        name,
                        companyId,
                    },
                },
            });
            erp = data.updateERPArea;
        } else {
            erp = await insertERP({ name, companyId });
        }
        const updatedContacts = await Promise.all(emergencyContacts?.items.map((contact) => updateContact({ ...contact, erpAreaId: erp.id })));
        return {
            ...erp,
            emergencyContacts: {
                items: updatedContacts,
            },
        };
    } catch (ex) {
        console.error("Failed to update company", ex);
    }
};
