import { createSelector, createSlice } from "@reduxjs/toolkit";
import { statuses, roles } from "../constants";
import service from "../service";
import { reset } from "./globalActions";
import isNotification from "../utils/isNotification";
import isOnShift from "../utils/isOnShift";
import isOnJourney from "../utils/isOnJourney";
import { API } from "aws-amplify";

const initialState = {
    checkIns: {},
    active: null,
    activeCompanyId: null,
    subscription: null,
    filter: null,
    filterString: "",
};

const checkInSlice = createSlice({
    name: "checkIn",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(reset, (state) => {
            state.subscription?.unsubscribe();
            return initialState;
        });
    },
    reducers: {
        setSubscription: (state, action) => {
            state.subscription = action.payload;
        },
        addCheckIn: (state, action) => {
            state.checkIns[action.payload.userId] = action.payload;
        },
        setCheckIns: (state, action) => {
            action.payload?.map((checkIn) => (state.checkIns[checkIn.userId] = checkIn));
        },
        setActiveCheckIn: (state, action) => {
            state.active = action.payload;
        },
        clearActiveCheckIn: (state, action) => {
            state.active = null;
        },
        setActiveCompanyId: (state, action) => {
            state.activeCompanyId = action.payload;
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setSearchFilter: (state, action) => {
            state.filter = "SEARCH"
            state.filterString = action.payload
        },
        clearSearchFilter: (state) => {
            state.filter = null;
            state.filterString = "";
        }
    },
});

const filterCheckIns = {
    OFF: (c) => c.status === statuses.OFF,
    SHIFT: (c) => isOnShift(c.status),
    JOURNEY: (c) => isNotification(c.status) || (isOnShift(c.status) && c.location?.speed * 3.6 > 40),
    PANIC: (c) => isNotification(c.status),
    SEARCH: (c, string) => (
        c.user?.firstName.toLowerCase().indexOf(string.toLowerCase()) > -1 ||
        c.user?.lastName.toLowerCase().indexOf(string.toLowerCase()) > -1
    )
};

export const { addCheckIn, clearActiveCheckIn, setSubscription, setCheckIns, setActiveCheckIn, setActiveCompanyId, setFilter, setSearchFilter, clearSearchFilter } = checkInSlice.actions;
export default checkInSlice.reducer;

export const selectState = (state) => state?.checkIn;
export const selectCheckIns = createSelector(selectState, (state) => Object.values(state.checkIns));
export const selectCompanyCheckIns = (state) => {
    const checkInState = selectState(state);
    let checkins = selectCheckIns(state);
    let filteredCheckins = checkins;
    const filter = checkInState.filter;
    const filterString = checkInState.filterString;

    if (filter && filterCheckIns[filter]) {
        filteredCheckins = checkins.filter(c => filterCheckIns[filter](c, filterString));
    }

    if (checkInState.activeCompanyId !== null) {
        return filteredCheckins.filter((c) => c.companyId === checkInState.activeCompanyId);
    }

    return filteredCheckins;
};
export const selectActiveCheckIn = createSelector(selectState, (state) => Object.values(state.checkIns).find((c) => c.userId === state.active));
export const selectCheckInSubscription = createSelector(selectState, (state) => state.subscription);
export const selectNotifications = createSelector(selectCompanyCheckIns, (checkIns) => checkIns.filter((c) => isNotification(c.status)));
export const selectFilter = createSelector(selectState, (state) => state.filter);
export const selectSearchFilter = createSelector(selectState, (state) => state.filterString);

export const getCheckIns = (companyId, role) => async (dispatch, getState) => {
    if (role === roles.OPERATOR) {
        const checkIns = await service.listCheckIns();
        dispatch(setCheckIns(checkIns));
    } else if (companyId) {
        const checkIns = await service.listCheckInsByCompanyId(companyId);
        dispatch(setCheckIns(checkIns));
    } else {
        console.log("Must specify a companyId if not superuser");
    }
};

export const subscribeCheckIns =
    (companyId, role, retry = 0) =>
        async (dispatch, getState) => {
            const subscriptionState = selectCheckInSubscription(getState());
            if (subscriptionState === null && companyId) {
                const subscription = await service.subscribeCheckIns(
                    ({ data }) => {
                        try {
                            const {
                                onUpdateCheckIn: checkIn,
                            } = data;
                            if (role === roles.OPERATOR || checkIn.companyId === companyId) {
                                dispatch(addCheckIn(checkIn));
                            }
                        } catch (ex) {
                            console.error("failed to update checkIn reducer", ex);
                        }
                    },
                    (error) => {
                        console.error("checkIn subscription failure", error);
                        // selectCheckInSubscription(getState())?.unsubscribe();
                        // dispatch(setSubscription(null));
                        // subscribeCheckIns(companyId, role, retry++ % 5)(dispatch, getState);
                    }
                );
                dispatch(setSubscription(subscription));
            }
        };

export const unsubscribeCheckIns = (dispatch, getState) => {
    try {
        selectCheckInSubscription(getState())?.unsubscribe();
        dispatch(setSubscription(null));
    } catch (ex) {
        console.log("failed to unsubscribe", ex);
    }
};

export const updateCheckIn =
    (email, status, location, companyId, manual = false) =>
        async (dispatch) => {
            let manualTimestamp;
            if (manual) {
                manualTimestamp = new Date();
            }
            await service.updateCheckIn({ email, status, location, companyId, manualTimestamp });
            // dispatch(onUpdateCheckin(company));  don't need because subscritpion is active
        };

export const requestLocation = (userId) => async (dispatch) => {
    await service.requestLocation(userId);
}