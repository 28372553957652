import { Amplify } from "aws-amplify";
import { signOut } from "aws-amplify/auth";
import "moment-duration-format";
import { createContext, useEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import awsConfig from "./amplifyconfiguration.json";
import { reset } from "./common/slices/globalActions";
import { refreshUserData, selectUser } from "./common/slices/user";
import AccessDenied from "./components/AccessDenied";
import Layout from "./components/Layout";
import ModalConfirm from "./components/ModalConfirm";
import ScreenAcceptTerms from "./components/ScreenAcceptTerms";
import ScreenForgotPassword from "./components/ScreenForgotPassword";
import ScreenForgotPasswordConfirm from "./components/ScreenForgotPasswordConfirm";
import ScreenHome from "./components/ScreenHome";
import ScreenLogin from "./components/ScreenLogin";
import ScreenManageCompany from "./components/ScreenManageCompany";
import ScreenAdmin from "./components/ScreenAdmin";
import ScreenNewPassword from "./components/ScreenNewPassword";
import ScreenPrivacy from "./components/ScreenPrivacy";
import SelfRegistrationAccountOwner from "./components/SelfRegistrationAccountOwner";
import SelfRegistrationCompany from "./components/SelfRegistrationCompany";
import SelfRegistrationERPGroup from "./components/SelfRegistrationERPGroup";
import SelfRegistrationLayout from "./components/SelfRegistrationLayout";
import SelfRegistrationPayment from "./components/SelfRegistrationPayment";
import SelfRegistrationTerms from "./components/SelfRegistrationTerms";
import SelfRegistrationThankYou from "./components/SelfRegistrationThankYou";
import SelfRegistrationWorkers from "./components/SelfRegistrationWorkers";
import SubscriptionSuspended from "./components/SubscriptionSuspended";
import useProtectedRoute from "./hooks/useProtectedRoute";
import { getUserSettings } from "./slices/app";
import store from "./store";
import ModalError from "./components/ModalError";
import { addCheckIn } from "./common/slices/checkIn";

Amplify.configure(awsConfig);

function App() {
    return (
        <BrowserRouter future={{
            v7_relativeSplatPath: true,
            v7_startTransition: true,
        }}>
            <Provider store={store}>
                <Routes>
                    <Route path="/auth/login" element={<ScreenLogin />} />
                    <Route path="/auth/new-password" element={<ScreenNewPassword />} />
                    <Route path="/auth/forgot-password" element={<ScreenForgotPassword />} />
                    <Route path="/auth/forgot-password-confirm" element={<ScreenForgotPasswordConfirm />} />
                    <Route path="/auth/registration" element={<SelfRegistrationLayout />}>
                        <Route path="" element={<SelfRegistrationTerms />} />
                        <Route path="account-owner" element={<SelfRegistrationAccountOwner />} />
                        <Route path="company" element={<SelfRegistrationCompany />} />
                        <Route path="erp-groups" element={<SelfRegistrationERPGroup />} />
                        <Route path="workers" element={<SelfRegistrationWorkers />} />
                        <Route path="payment" element={<SelfRegistrationPayment />} />
                    </Route>
                    <Route path="/thank-you" element={<SelfRegistrationThankYou />} />
                    <Route path="/payment-redirect" element={<ScreenPrivacy />} />
                    <Route path="/privacy" element={<ScreenPrivacy />} />
                    <Route path="/terms-of-use" element={<ScreenAcceptTerms />} />
                    <Route path="/" element={<BeSafe />}>
                        <Route path="/" element={<ScreenHome />} />
                        <Route path="/admin/:tabName?" element={<ScreenAdmin />} />
                        <Route path="/company-settings" element={<ScreenManageCompany />} />
                        <Route path="*" element={<div>Not Found</div>} />
                    </Route>
                </Routes>
            </Provider>
        </BrowserRouter>
    );
}

export const PrivacyContext = createContext(false);
export const ErrorContext = createContext({ errorMessage: "", setError: () => { } });

const BeSafe = ({ user }) => {
    const nav = useNavigate();
    const [confirm, setConfirm] = useState(false);
    const [errorMessage, setError] = useState(null);
    const u = useSelector(selectUser);


    useProtectedRoute();
    // useMustAcceptTerms(u, TERMS_VERSION);

    const [privacy, setPrivacy] = useState(false);

    const onKeyDown = (event) => {
        const authUser = selectUser(store.getState())
        if (event.ctrlKey && event.key === 'd') {
            event.preventDefault();
            setPrivacy(s => !s);
        } else if (event.ctrlKey && event.key === 'a' && authUser.role === "OPERATOR") {
            event.preventDefault();
            const fakeCheckIn = { userId: authUser.email, status: "SHIFT_NOT_RESPONDING", user: authUser, company: authUser.company, location: { lat: 37.2346942, lng: -115.8162649, speed: 0, heading: 0, accuracy: 0 }, timestamp: new Date().toISOString() };
            store.dispatch(addCheckIn(fakeCheckIn))
        } else if (event.ctrlKey && event.key === 's' && authUser.role === "OPERATOR") {
            event.preventDefault();
            const fakeCheckIn = { userId: authUser.email, status: authUser.status, user: authUser, company: authUser.company, location: { lat: 37.2346942, lng: -115.8162649, speed: 0, heading: 0, accuracy: 0 }, timestamp: new Date().toISOString() };
            store.dispatch(addCheckIn(fakeCheckIn))
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown)

        //Called when the user presses the back button instead of clicking the 'X' on the modal
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        }
    }, []);


    useEffect(() => {
        store.dispatch(refreshUserData);
        store.dispatch(getUserSettings(user?.signInDetails?.loginId));
    }, [user]);

    if (u && u.role === "WORKER") {
        return <AccessDenied />;
    }

    if (u && u.company?.subscriptionStatus === "SUSPENDED") {
        return <SubscriptionSuspended />
    }

    const onSignOut = async () => {
        await signOut();
        nav('/auth/login');
        store.dispatch(reset());
    }


    return (
        <ErrorContext.Provider value={{ errorMessage, setError }}>
            <PrivacyContext.Provider value={privacy}>
                <Layout user={user} signOut={() => setConfirm(true)}>
                    <Outlet />
                    {
                        confirm &&
                        <ModalConfirm
                            title="Sign Out Confirmation"
                            description="Are you sure you want to sign out?  Any active work-alone session will end, and you will no longer be able to monitor workers for safety."
                            confirmButtonTitle="Sign Out"
                            onClose={() => setConfirm(false)}
                            onConfirm={onSignOut}
                        />
                    }
                    {
                        errorMessage &&
                        <ModalError errorMessage={errorMessage} onClose={() => setError(null)} />
                    }
                </Layout>
            </PrivacyContext.Provider>
        </ErrorContext.Provider>
    );
};

export default App;
