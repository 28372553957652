import { generateClient } from "aws-amplify/api";
import { satelliteDevicesByIMEI } from '../graphql/queries';

const client = generateClient();

const re = /\b\d{15}\b/;

let promise;

export default async (imei) => {
    try {

        const match = imei.match(re);

        if (!match) {
            return "Invalid IMEI number"
        }

        if (promise) client.cancel(promise, 'cancelled request');

        promise = client.graphql({
            query: satelliteDevicesByIMEI,
            variables: { imei }
        })

        const { data: { satelliteDevicesByIMEI: { items } } } = await promise;
        return items.length === 0 ? true : 'IMEI Already Exists in Be Safe'
    } catch (ex) {
        return ex.message;
    }
}