import { getCompany, searchCompanies } from "../common/graphql/queries";
import QueryBuilder from '../common/service/QueryBuilder';
import FormSelectInput from './FormSelectInput';

const listAll = new QueryBuilder(searchCompanies, "searchCompanies", null);
const getOne = new QueryBuilder(getCompany, "getCompany", null);
const FormSelectCompany = ({ role, companyId, ...props }) => {
    const loadOptions = async (input) => {
        if (role === "OPERATOR") {
            listAll.setParams({ sort: [{ field: 'name', direction: 'asc' }] })
            listAll.setFilter({ name: { wildcard: `*${input}*` } })
            const { items } = await listAll.execute();
            return items.map(i => ({ value: i.id, label: i.name }))
        } else {
            getOne.setParams({ id: companyId })
            const company = await getOne.execute();
            return [{ value: company.id, label: company.name }]
        }
    }

    return (
        <FormSelectInput name="companyId" placeholder="Select a company" label="Company" loadOptions={loadOptions} {...props} />
    )
}

export default FormSelectCompany;