import { faChevronDown, faChevronUp, faCircleDown, faCirclePlus, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createContext, useContext, useState } from 'react';
import styled from 'styled-components';
import theme from '../common/theme';
import { P } from './Typography';

const AccordionContainer = styled.div`
    border-bottom: ${props => props.border ? `2px solid ${theme.palette.blue}` : 'none'};
`

const AccordionContext = createContext();

export const Accordion = ({ children, initialOpen = 0 }) => {
    const [active, setActive] = useState(initialOpen);
    let context = {
        active,
        setActive,
    };

    return (
        <AccordionContext.Provider value={context}>
            {children}
        </AccordionContext.Provider>
    )
}

const AccordionHeader = ({ active, title, onClick, actions }) => {
    return (
        <div
            onClick={onClick}
            style={{
                display: 'table-row',
                gap: '0.5rem',
                display: 'flex',
                padding: '0.5rem',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: active ? undefined : '0.25rem 0',
                borderBottom: active ? `1px solid ${theme.palette.lightBlue}` : 'none'
            }}>
            {
                active ?
                    <FontAwesomeIcon icon={faChevronDown} color={theme.palette.lightBlue} /> :
                    <FontAwesomeIcon icon={faChevronUp} />
            }
            <P noMargin style={{ marginLeft: '1rem', marginRight: 'auto', color: active ? theme.palette.lightBlue : 'inherit', fontWeight: active ? 700 : 'inherit' }}>{title}</P>
            {
                active &&
                <div style={{ marginLeft: 'auto' }}>
                    {actions}
                </div>
            }
        </div>
    )
}

export const AccordionItem = ({ children, header, id, actions, border = false }) => {
    const ctx = useContext(AccordionContext);
    const active = ctx.active === id;
    return (
        <AccordionContainer border={border}>
            <AccordionHeader
                active={active}
                onClick={() => active ? ctx.setActive(-1) : ctx.setActive(id)}
                title={header}
                actions={actions}
            />
            {
                active &&
                <div style={{
                    padding: '1rem 0',
                    borderTop: 'none',
                    border: border ? `1px solid ${theme.palette.blue}` : 'none',
                }}>
                    {children}
                </div>
            }
        </AccordionContainer>
    )
}

export const AccordionTable = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 1rem;
    background: ${theme.palette.tableRow};
`

export const AccordionSubTable = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.5rem 1rem;

    &:not(:last-child):not(:first-child) {
        border-bottom: 1px solid ${theme.palette.border};
    }
`

export const AccordionTableHeader = styled(AccordionSubTable)`
    background: #000;
    font-weight: 700;
    padding: 0 1rem;
    margin: 0;
`

export const ERPData = styled.div`
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.25rem 0;
`

export const HeaderData = styled(ERPData)`
    padding: 1rem 0;
`