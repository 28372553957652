import { getUrl } from "aws-amplify/storage";
import { useEffect, useState } from "react";

export default ({ imgKey, ...props }) => {
    const [uri, setUri] = useState(null);
    useEffect(() => {
        const getUri = async (k) => {
            const resp = await getUrl({ path: `public/${k}` });
            setUri(resp.url.toString());
        };

        if (imgKey) {
            getUri(imgKey);
        }
    }, [imgKey]);

    if (!uri) {
        return <></>;
    }
    return <img src={uri} {...props} />;
};
