import { generateClient } from "aws-amplify/api";
import { deleteERPArea } from "../graphql/mutations";

const client = generateClient();

export default async ({ id }) => {
    try {
        const {
            data: { deleteERPArea: deletedERP },
        } = await client.graphql({
            query: deleteERPArea,
            variables: {
                input: {
                    id,
                },
            },
        });
        return deletedERP;
    } catch (ex) {
        console.error("Failed to delete ERP Area", ex);
    }
};
