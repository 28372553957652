import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { deleteCompany, selectActiveCompany, updateCompany } from '../common/slices/company';
import { BlueButton, ButtonBase, RedButton } from "./Buttons";
import Divider from "./Divider";
import FormERP from "./FormERP";
import { NOTIFICATION_TYPE_MAP } from "./FormSelectNotificationTypes";
import FormTextArea from "./FormTextArea";
import FormTextInput from "./FormTextInput";
import Modal, { Container, Footer, Header } from './Modal';
import ModalConfirm from "./ModalConfirm";
import Row from "./Row";
import { H1 } from "./Typography";

const unmarshall = ({ id, name, erpAreas, contactNotes, createdAt, customerId, subscriptionId }) => ({
    id,
    name,
    contactNotes,
    createdAt,
    customerId: customerId || undefined,
    subscriptionId: subscriptionId || undefined,
    erpAreas: erpAreas.items.map(area => ({
        ...area,
        emergencyContacts: area.emergencyContacts?.items?.map(({ notifyBy, notificationTypes, ...i }) => ({
            ...i,
            notificationTypes: notificationTypes?.map(nt => nt.value),
            notifyBySMS: !!notifyBy?.find(v => v?.value === "SMS"),
            notifyByEmail: !!notifyBy?.find(v => v?.value === "EMAIL"),
        }))
    }))
})

const marshall = ({ id, name, erpAreas, contactNotes, createdAt, customerId, subscriptionId }) => ({
    id,
    name,
    contactNotes,
    createdAt,
    customerId,
    subscriptionId,
    erpAreas: {
        items: erpAreas.items.map(area => ({
            ...area,
            emergencyContacts: {
                items: area.emergencyContacts?.items?.map(({ notifyBy, notificationTypes, ...i }) => ({
                    ...i,
                    notificationTypes: notificationTypes?.map(nt => ({ value: nt, label: NOTIFICATION_TYPE_MAP[nt] })),
                    notifyBy: [
                        i.notifyBySMS ? ({ value: "SMS", label: "sms" }) : undefined,
                        i.notifyByEmail ? ({ value: "Email", label: "email" }) : undefined,
                    ]
                }))
            }
        }))
    }
})

const ModalAddCompany = ({ show, onClose }) => {
    const activeCompany = useSelector(selectActiveCompany);
    const dispatch = useDispatch();
    const [showConfirm, setShowConfirm] = useState(false);

    const onSaveClick = async (values) => {
        const toUpdate = unmarshall(values);
        await dispatch(updateCompany(toUpdate));
        onClose();
    }

    const onDelete = async () => {
        await dispatch(deleteCompany(activeCompany));
        onClose();
    }

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting, isDirty },
    } = useForm({
        defaultValues: marshall(activeCompany)
    })

    const fieldProps = { control, errors };

    return (
        <Modal
            show={show}
            onClose={() => {
                if (!isDirty) {
                    onClose();
                }
            }}
        >
            <Container>
                <Header>
                    <H1 onClick={() => navigator.clipboard.writeText(activeCompany?.id)}>Manage Company</H1>
                    <ButtonBase onClick={onClose} disabled={isSubmitting}><FontAwesomeIcon icon={faTimesCircle} size={'2x'} /></ButtonBase>
                </Header>
                <Row>
                    <FormTextInput {...fieldProps} rules={{ required: true }} name="name" placeholder="Enter Company Name" label="Company Name" />
                    <FormTextInput {...fieldProps} name="customerId" placeholder="Enter CustomerId" label="Stripe CustomerId" />
                    <FormTextInput {...fieldProps} name="subscriptionId" placeholder="Enter SubscriptionId" label="Stripe SubscriptionId" />
                </Row>
                <FormTextArea {...fieldProps} name="contactNotes" label="Notes" placeholder="Enter notes for this company (ie. don't contact after 6pm)" />
                <Divider />
                <FormERP {...fieldProps} />
                <Footer>
                    <BlueButton onClick={handleSubmit(onSaveClick)} disabled={isSubmitting || !isValid}>Save</BlueButton>
                    <RedButton onClick={() => setShowConfirm(true)} disabled={isSubmitting || !activeCompany?.id} style={{ marginLeft: 'auto' }}>Delete</RedButton>
                    <ButtonBase onClick={onClose} disabled={isSubmitting} >Cancel</ButtonBase>
                </Footer>
            </Container>

            {
                showConfirm &&
                <ModalConfirm
                    title="Delete Confirmation"
                    description={`
                    Are you sure you want to permanently delete ${activeCompany?.name} and its associated data?<br /><br />
                        The following will be deleted:
                        <ul>
                            <li>All User Accounts</li>
                            <li>ERP Groups</li>
                            <li>Shift Archives</li>
                            <li>Shift Schedules</li>
                            <li>Alert History</li>
                            <li>Emergency Contacts</li>
                            <li>Satellite Devices</li>
                            <li>Satellite Device Associations</li>
                        </ul>
                        Any usage logged during the current invoice period will still be invoiced at the end of the invoice period.<br /><br />
                        This action is <b>permanent and cannot be undone</b>.
                    `
                    }
                    confirmButtonTitle="Confirm Delete"
                    onConfirm={handleSubmit(onDelete)}
                    onClose={() => setShowConfirm(false)}
                    disabled={isSubmitting}
                />
            }
        </Modal>
    )
}

export default ModalAddCompany