import { Marker } from "@react-google-maps/api";
import moment from "moment";
import { useSelector } from 'react-redux';
import alert from '../assets/pointOfInterest-alert.svg';
import ok from '../assets/pointOfInterest-ok.svg';
import friendlyStatus from "../common/utils/friendlyStatus";
import isNotification from '../common/utils/isNotification';
import { selectArchive } from '../slices/archive';

const PointsOfInterest = ({ map, archive }) => {
    return archive?.map((p, i) => (
        <Marker
            key={i}
            position={p.location}
            title={friendlyStatus(p.status)}
            icon={{
                url: isNotification(p.status) ? alert : ok,
                anchor: new window.google.maps.Point(11, 11),
            }}
            onClick={function () {
                const info = new window.google.maps.InfoWindow({
                    maxWidth: "400px",
                    content: `
                        <style>
                            .tooltip {
                                display: flex;
                                flex-direction: column;
                                gap: 0.25rem;
                                width: 400px;
                            }
                            section {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 1rem;
                            }

                            h1 {
                                font-family: "SF Pro";
                                font-size: 1rem;
                                margin: 0;
                            }

                            label {
                                font-family: "SF Pro";
                                font-weight: 700;
                                min-width: 200px;
                            }

                            section > p {
                                font-family: "SF Pro";
                                margin: 0;
                            }
                        </style>
                        <div class="tooltip">
                            <h1>${friendlyStatus(p.status)}</h1>
                            <section>
                                <label>Timestamp</label>
                                <p>${moment(p.timestamp).format("MMMM DD, YYYY [at] h:mm a")}</p>
                            </section>
                            <section>
                                <label>Location</label>
                                <p>${p.location.lat?.toFixed(3)}, ${p.location.lng?.toFixed(3)}</p>
                            </section>
                            <section>
                                <label>Speed</label>
                                <p>${(p.location?.speed * 3.6).toFixed(2)} km/h</p>
                            </section>
                            <section>
                                <label>Heading</label>
                                <p>${p?.location.heading?.toFixed(0)}\u00B0 <span style="font-weight: 700; display: inline-block; transform: rotate(${p?.location.heading}deg); transition: all 1s;">^</span></p>
                            </section>
                            <section>
                                <label>Accuracy</label>
                                <p>${p?.location.accuracy?.toFixed(2)} meters</p>
                            </section>
                            <br />
                            <small>Various factors could effect the accuracy of the displayed location</small>
                        </div>
                    `,
                    ariaLabel: "Checkin details",
                })
                info.open({
                    anchor: this,
                    map,
                })
            }}
        />
    ))
}

export default PointsOfInterest;

