import { MarkerClusterer } from "@react-google-maps/api";
import { forwardRef, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectActiveCheckIn, selectCompanyCheckIns } from "../common/slices/checkIn";


import isNotification from "../common/utils/isNotification";
import Map from "./Map";
import CheckInMarker from "./MarkerCheckIn";
import _ from "lodash";
import { PrivacyContext } from "../App";

const getKey = (seed) => {
    return _.snakeCase(seed);
}

const clusterOptions = {
    styles: [
        {
            textColor: 'white',
            textSize: 12,
            textDecoration: 'bold',
            url: '/markers/m1.png',
            height: 30,
            width: 30,
        },
        {
            textColor: 'white',
            textSize: 12,
            textDecoration: 'bold',
            url: '/markers/m2.png',
            height: 40,
            width: 40,
        },
        {
            textColor: 'white',
            textSize: 12,
            textDecoration: 'bold',
            url: '/markers/m3.png',
            height: 50,
            width: 50,
        },
        {
            textColor: 'white',
            textSize: 12,
            textDecoration: 'bold',
            url: '/markers/m4.png',
            height: 60,
            width: 60,
        },
        {
            textColor: 'white',
            textSize: 12,
            textDecoration: 'bold',
            url: '/markers/m5.png',
            height: 70,
            width: 70,
        }
    ]
}

const CheckIns = ({ map, onClick }) => {
    const checkIns = useSelector(selectCompanyCheckIns);
    const activeCheckIn = useSelector(selectActiveCheckIn);
    const privacy = useContext(PrivacyContext);

    useEffect(() => {
        if (map && !activeCheckIn) {
            const bounds = new window.google.maps.LatLngBounds();
            const coords = checkIns.map(checkIn => ({
                latitude: checkIn.location.lat,
                longitude: checkIn.location.lng
            }))
            coords.map(coord => bounds.extend({ lat: coord.latitude, lng: coord.longitude }))
            map.fitBounds(bounds, 10)
        }

        // eslint-disable-next-line
    }, [map, checkIns.length])

    const panics = checkIns.filter(checkIn => isNotification(checkIn.status));
    const nonPanic = checkIns.filter(checkIn => !isNotification(checkIn.status));

    return (
        [
            panics?.map(checkIn => <CheckInMarker key={`${getKey(checkIn.userId)}_panic`} checkIn={checkIn} onClick={onClick} privacy={privacy} />),
            <MarkerClusterer key={'marker-cluster'} options={clusterOptions}>
                {(clusterer) => {
                    return nonPanic?.map(checkIn => <CheckInMarker key={`${getKey(checkIn.userId)}_non_panic`} checkIn={checkIn} onClick={onClick} clusterer={clusterer} privacy={privacy} />)
                }}
            </MarkerClusterer>
        ]
    )
}

const MapCheckIn = forwardRef(({ onClick, privacy, ...props }, ref) => {
    return (
        <Map
            ref={ref}
            {...props}
        >
            <CheckIns onClick={onClick} privacy={privacy} />
        </Map>
    )
});

export default MapCheckIn;