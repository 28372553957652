import { generateClient } from "aws-amplify/api";
import { getDirections } from '../graphql/customQueries';

const client = generateClient();

export default async (start, end) => {
    try {
        const input = {
            origin: [start.latitude, start.longitude],
            destination: end
        }
        const directions = await client.graphql({
            query: getDirections,
            variables: {
                input
            }
        })
        return directions;
    } catch(ex) {
        console.error("Failed to get directions", ex);
    }
}