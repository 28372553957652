import styled from "styled-components"

export default styled.span`
    background-color: ${props => props.color};
    border-color: ${props => props.color};
    border-width: 1px;
    border-radius: 1rem;
    padding: 0.2rem 0.5rem;
    font-weight: 200;
    font-size: 0.8rem;
    display: inline-block;

    filter: ${props => props.dataPrivacy ? 'blur(0.4rem)' : ''}
`
