import { Marker } from "@react-google-maps/api";
import OFF from '../assets/OFF.png';
import SHIFT from '../assets/SHIFT.png';
import SHIFT_ACCIDENT from '../assets/SHIFT_ACCIDENT.png';
import SHIFT_NOT_RESPONDING from '../assets/SHIFT_NOT_RESPONDING.png';
import SHIFT_OK from '../assets/SHIFT_OK.png';
import SHIFT_PANIC from '../assets/SHIFT_PANIC.png';
import friendlyStatus from "../common/utils/friendlyStatus";
import moment from "moment";


const statusMap = {
    SHIFT,
    SHIFT_OK,
    SHIFT_PANIC,
    SHIFT_NOT_RESPONDING,
    SHIFT_ACCIDENT,
    OFF
}

const MarkerArchive = ({ checkIn, privacy, map, ...props }) => {
    return (
        <Marker
            {...props}
            position={checkIn?.location}
            onClick={function () {
                const info = new window.google.maps.InfoWindow({
                    minWidth: "450px",
                    content: `
                        <style>
                            .tooltip {
                                display: flex;
                                flex-direction: column;
                                gap: 0.25rem;
                                width: 400px;
                            }
                            section {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 1rem;                                
                            }

                            h1 {
                                font-family: "SF Pro";
                                font-size: 1rem;
                                margin: 0;
                            }

                            label {
                                font-family: "SF Pro";
                                font-weight: 700;
                                min-width: 150px;
                            }

                            section > p {
                                font-family: "SF Pro";
                                margin: 0;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                        </style>
                        <div class="tooltip">
                            <h1>${friendlyStatus(checkIn.status)}</h1>
                            <section>
                                <label>User</label>
                                <p>${checkIn.email}</p>
                            </section>
                            <section>
                                <label>Timestamp</label>
                                <p>${moment(checkIn.timestamp).format("MMMM DD, YYYY [at] h:mm a")}</p>
                            </section>
                            <section>
                                <label>Location</label>
                                <p>${checkIn.location.lat?.toFixed(3)}, ${checkIn.location.lng?.toFixed(3)}</p>
                            </section>
                            <section>
                                <label>Speed</label>
                                <p>${(checkIn.location?.speed * 3.6).toFixed(2)} km/h</p>
                            </section>
                            <section>
                                <label>Heading</label>
                                <p>${checkIn?.location.heading?.toFixed(0)}\u00B0 <span style="font-weight: 700; display: inline-block; transform: rotate(${checkIn?.location.heading}deg); transition: all 1s;">^</span></p>
                            </section>
                            <section>
                                <label>Accuracy</label>
                                <p>${checkIn?.location.accuracy?.toFixed(2)} meters</p>
                            </section>
                            <br />
                            <small>Various factors could effect the accuracy of the displayed location</small>
                        </div>
                    `,
                    ariaLabel: "Checkin details",
                })
                info.open({
                    anchor: this,
                    map,
                })
            }}
            icon={{
                url: statusMap[checkIn.status],
                scaledSize: new window.google.maps.Size(30, 30),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(15, 30),
            }}
        />
    )
}

export default MarkerArchive;