import { generateClient } from "aws-amplify/api";
import { getCompany } from "../graphql/customQueries";

const client = generateClient();

export default async (companyId) => {
    try {
        const {
            data: { getCompany: company },
        } = await client.graphql({
            query: getCompany,
            variables: {
                id: companyId,
            },
        });
        return company;
    } catch (e) {
        console.error("failed to fetch company", e);
    }
};
