import { generateClient } from "aws-amplify/api";
import { customDeleteCompany } from "../graphql/mutations";

const client = generateClient();

export default async ({ id }) => {
    try {
        const {
            data: { customDeleteCompany: response },
        } = await client.graphql({
            query: customDeleteCompany,
            variables: {
                input: {
                    companyId: id,
                },
            },
        });
        return response;
    } catch (ex) {
        console.error("Failed to update company", ex);
    }
};
