import { generateClient } from "aws-amplify/api";
import { deleteEmergencyContact } from "../graphql/mutations";

const client = generateClient();

const deleteContact = async ({ id }) => {
    try {
        const {
            data: { deleteEmergencyContact: newEntity },
        } = await client.graphql({
            query: deleteEmergencyContact,
            variables: {
                input: {
                    id,
                },
            },
        });
        return newEntity;
    } catch (ex) {
        console.log("failed to delete contact", ex);
    }
};

export default deleteContact;
