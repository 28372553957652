import { signOut } from 'aws-amplify/auth';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../assets/logo.svg';
import { reset } from '../common/slices/globalActions';
import theme from '../common/theme';
import { RedButton } from './Buttons';
import { Logo, TopNavContainer } from './TopNav';
import { H1, P } from './Typography';

const Container = styled.div`
    background-color: ${theme.palette.border};
    color: white;
    margin: 0 auto;
    width: 50%;
    padding: 3rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
`

const AccessDenied = () => {
    const nav = useNavigate();
    const dispatch = useDispatch();

    const logout = async () => {
        await signOut();        
        nav('/auth/login')
        dispatch(reset());
    }

    return (
        <>
            <TopNavContainer style={{ paddingBottom: '1rem' }}>
                <Logo src={logo} />
            </TopNavContainer>
            <Container>
                <H1>Access Denied</H1>
                <P>You do not have permission to access this page.</P>
                <P>This area is restricted to supervisors and authorized personnel only.  If you believe this is an error or need access for a specific reason, please contact your supervisor.</P>
                <RedButton onClick={logout}>Logout</RedButton>
            </Container>
        </>
    )
}

export default AccessDenied;
