import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { deleteCompany, selectActiveCompany, updateCompany } from '../common/slices/company';
import { BlueButton, ButtonBase, RedButton } from "./Buttons";
import Divider from "./Divider";
import FormERP from "./FormERP";
import { NOTIFICATION_TYPE_MAP } from "./FormSelectNotificationTypes";
import FormTextArea from "./FormTextArea";
import FormTextInput from "./FormTextInput";
import Modal, { Container, Footer, Header } from './Modal';
import { H1 } from "./Typography";
import Row from "./Row";
import FormEmailInput from "./FormEmailInput";
import FormPhoneNumber from "./FormPhoneNumber";
import { FormSelectTimezone, tzOptions } from "./FormSelectTimezone";

const unmarshall = ({ id, name, erpAreas, contactNotes, createdAt, customerId, subscriptionId, utcOffset }) => ({
    id,
    name,
    contactNotes,
    createdAt,
    customerId,
    subscriptionId,
    utcOffset: utcOffset?.value,
    erpAreas: erpAreas.items.map(area => ({
        ...area,
        emergencyContacts: area.emergencyContacts?.items?.map(({ notifyBy, notificationTypes, ...i }) => ({
            ...i,
            notificationTypes: notificationTypes?.map(nt => nt.value),
            notifyBySMS: !!notifyBy?.find(v => v?.value === "SMS"),
            notifyByEmail: !!notifyBy?.find(v => v?.value === "EMAIL"),
        }))
    }))
})

const marshall = ({ id, name, erpAreas, contactNotes, createdAt, customerId, subscriptionId, utcOffset }) => ({
    id,
    name,
    contactNotes,
    createdAt,
    customerId,
    subscriptionId,
    utcOffset: { value: utcOffset, label: tzOptions.find(tz => tz.value === utcOffset)?.label },
    erpAreas: {
        items: erpAreas.items.map(area => ({
            ...area,
            emergencyContacts: {
                items: area.emergencyContacts?.items?.map(({ notifyBy, notificationTypes, ...i }) => ({
                    ...i,
                    notificationTypes: notificationTypes?.map(nt => ({ value: nt, label: NOTIFICATION_TYPE_MAP[nt] })),
                    notifyBy: [
                        i.notifyBySMS ? ({ value: "SMS", label: "sms" }) : undefined,
                        i.notifyByEmail ? ({ value: "Email", label: "email" }) : undefined,
                    ]
                }))
            }
        }))
    }
})

const ModalManageCompanyERP = ({ onClose, company }) => {
    const dispatch = useDispatch();

    const onSaveClick = async (values) => {
        const toUpdate = unmarshall(values);
        await dispatch(updateCompany(toUpdate));
        onClose();
    }

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting, isDirty },
    } = useForm({
        defaultValues: marshall(company)
    })

    const fieldProps = { control, errors };

    return (
        <Modal
            show
            onClose={() => {
                if (!isDirty) {
                    onClose();
                }
            }}
        >
            <Container>
                <Header>
                    <H1 onClick={() => navigator.clipboard.writeText(company?.id)}>Manage ERP Groups</H1>
                    <ButtonBase onClick={onClose} disabled={isSubmitting}><FontAwesomeIcon icon={faTimesCircle} size={'2x'} /></ButtonBase>
                </Header>
                <FormERP {...fieldProps} />
                <Footer>
                    <BlueButton onClick={handleSubmit(onSaveClick)} disabled={isSubmitting || !isValid}>Save</BlueButton>
                    <ButtonBase onClick={onClose} disabled={isSubmitting} style={{ marginLeft: 'auto' }}>Cancel</ButtonBase>
                </Footer>
            </Container>
        </Modal>
    )
}

export default ModalManageCompanyERP