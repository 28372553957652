import { generateClient } from "aws-amplify/api";
import { getSummary } from "../graphql/queries";

const client = generateClient();

export default async ({startDate, endDate, companyId, userId}) => {
    try {
        const {
            data: {
                getSummary: summary
            },
        } = await client.graphql({
            query: getSummary,
            variables: {
                input: {
                    startDate,
                    endDate,
                    companyId,
                    userId,
                },
            },
        });
        return summary;
    } catch (ex) {
        console.warn("get summary failed", ex);
    }
};
