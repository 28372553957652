import { useContext, useState } from "react";
import styled from "styled-components";
import TabContext from './tabContext';
import { H2 } from "./Typography";
import tabContext from "./tabContext";
import theme from "../common/theme";

export const TabContainer = styled((props) => {
    const [active, setActive] = useState(0);
    return <TabContext.Provider value={{ active, setActive }}><div {...props} /></TabContext.Provider>
})`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
`

export const TabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    margin-bottom: 2rem;
`;

export const EmptyTab = styled.div`
    border-bottom: 2px solid ${theme.palette.tabInactive};
    flex: 1;
`

export const Tab = styled(({ tabId, style, onClick, ...props }) => {
    const context = useContext(tabContext);
    const active = (context.active === tabId);
    const color = active ? theme.palette.lightBlue : theme.palette.tabInactive

    return (
        <H2
            {...props}
            style={{
                // backgroundColor: `${context.active === tabId ? theme.palette.lightBlue : theme.palette.blue}`
                color,
                paddingBottom: '0.25rem',
                borderBottom: `2px solid ${color}`,
                ...style,
            }}
            onClick={() => {
                context.setActive(tabId)
                if (onClick) { onClick() }
            }}
        />
    )
})`
    padding: 0 0.5rem;
    color: white;
    font-weight: 700;
    cursor: pointer;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 14px;
`;

export const TabContent = styled(({ tabId, ...props }) => {
    return (
        <TabContext.Consumer>
            {(context) => {
                if (context.active !== tabId) {
                    return null;
                }
                return <div {...props} />
            }}
        </TabContext.Consumer>
    )
})`
    flex: 0;
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
`;