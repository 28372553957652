import { generateClient } from "aws-amplify/api";
import { updateWholeCompany } from "../graphql/mutations";

const client = generateClient();

export default async (updatedCompany) => {
    try {
        let { data } = await client.graphql({
            query: updateWholeCompany,
            variables: {
                input: updatedCompany,
            },
        });

        return data.updateWholeCompany;
    } catch (ex) {
        console.error("Failed to update company", ex);
    }
};
