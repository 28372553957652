import { generateClient } from "aws-amplify/api";
import { validateEmail } from '../graphql/queries'
import { EMAIL_PATTERN } from "../constants"

const client = generateClient();

export default (authMode = "apiKey") => async (email) => {
    const match = email.match(EMAIL_PATTERN);

    if (!match) {
        return "Invalid email address"
    }

    const { data: { validateEmail: result } } = await client.graphql({
        query: validateEmail,
        authMode,
        variables: {
            input: { email }
        }
    })

    if (result.success) {
        return true;
    } else {
        return result.message;
    }
}