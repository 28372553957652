import { generateClient } from "aws-amplify/api";
import { statuses } from "../constants";
import { updateUser } from "../graphql/mutations";

const client = generateClient();

export default async (email) => {
    try {
        const {
            data: { updateUser: updatedUser },
        } = await client.graphql({
            query: updateUser,
            variables: {
                input: {
                    email,
                    status: statuses.OFF,
                },
            },
        });
        return updatedUser;
    } catch (ex) {
        console.log("Failed to end shift", ex);
        return false;
    }
};
