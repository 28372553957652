import { faCircle, faClock, faRecycle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from 'moment'
import { useContext, useEffect, useState } from "react"
import { Calendar, momentLocalizer } from "react-big-calendar"
import { useDispatch, useSelector } from 'react-redux'
import styled from "styled-components"
import reduceSchedules from "../common/service/reduceSchedules"
import { fetchCompanySchedules, selectSchedules } from "../common/slices/schedule"
import { selectUser } from '../common/slices/user'
import theme from "../common/theme"
import { getColor } from "../service/colors"
import Badge from "./Badge"
import Role from "./Role"
import Row from "./Row"
import { TabContent } from "./Tabs"
import { H3, P, P2 } from "./Typography"
import { PrivacyContext } from "../App"


const localizer = momentLocalizer(moment) // or globalizeLocalizer

const Empty = styled.div`
    flex: 1;
    text-align: center;
    color: #000;
`;

const TableShiftSchedule = () => {
    const privacy = useContext(PrivacyContext);
    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const schedules = useSelector(selectSchedules);
    const [filter, setFilter] = useState("");

    const onFilterClick = (newFilter) => {
        if (newFilter !== filter) {
            setFilter(newFilter);
        } else {
            setFilter("")
        }
    }

    useEffect(() => {
        if (user) {
            dispatch(fetchCompanySchedules(user.companyId))
        }
    }, [user])

    const events = schedules
        .filter(s => s.email.indexOf(filter) >= 0)
        .reduce(reduceSchedules, []);

    const eventPropGetter = (event) => {
        const color = getColor(event.scheduleId);
        return {
            style: {
                background: color,
                border: `1px solid ${color}`,
            }
        }
    }

    const EventDisplay = ({ event, ...props }) => {
        return (
            <div {...props}>
                {event.type === "RecurringShiftPattern" ? <FontAwesomeIcon icon={faRecycle} size="sm" /> : <FontAwesomeIcon icon={faClock} size="sm" />} {event.title}<br />
                <Role role={event.role} style={{ marginTop: '0.25rem', marginBottom: "0.25rem" }} />
                <span style={{ fontSize: '0.9em', display: 'block' }}><FontAwesomeIcon icon={faCircle} color={getColor(event.email)} /> {event.user}</span>
                <P2>{event.description}</P2>
            </div>
        )
    }

    return (
        <>
            <Row style={{ flex: 1, flexDirection: 'column', backgroundColor: theme.palette.tableRow, padding: '1rem' }}>
                {
                    schedules.length > 0 &&
                    <Calendar
                        selectable
                        localizer={localizer}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ width: '100%', height: '800px' }}
                        views={['day', 'week', 'month']}
                        eventPropGetter={eventPropGetter}
                        defaultView="week"
                        className={`besafe-calendar ${privacy ? 'privacy' : ''}`}
                        components={{ event: EventDisplay }}
                    />
                }

                {
                    !schedules.length &&
                    <Empty>
                        <P>No shift schedules are defined for any company users.<br />Please create schedules for users individually.</P>
                    </Empty>
                }

            </Row>
            {
                schedules.length !== 0 &&
                <>
                    <H3>Users With Schedules</H3>
                    {
                        schedules.map(s => (
                            <Badge dataPrivacy={privacy} key={s.id} onClick={() => onFilterClick(s.email)} color={getColor(s.email)} style={{ cursor: 'pointer', marginRight: '0.25rem' }}>
                                {filter === s.email && <FontAwesomeIcon icon={faCircle} color={theme.palette.dark} size="sm" style={{ marginRight: '0.5rem' }} />}{s.user.firstName} {s.user.lastName}
                            </Badge>
                        ))
                    }
                </>
            }
        </>
    )
}

export default () => (
    <TabContent tabId={3}>
        <TableShiftSchedule />
    </TabContent>
)