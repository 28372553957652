import styled from "styled-components";
import theme from "../common/theme";

const ProgressBar = styled.div`
    width: 100%;
    height: ${props => props.height || '1rem'};
    background: rgba(41, 44, 84, 1);
    // border: 1px solid ${theme.palette.blue};
    padding: 1px;
    overflow: hidden;
    margin: 0.3rem 0;
`

const Progress = styled.div`
    position: relative;
    height: 100%;
    transition: width 1s ease-in-out;
`

// width: ${ props => props.progress }%;
// background: ${ props => props.alarm ? theme.palette.yellow : theme.palette.green };

export default ({ progress, height, alarm, ...rest }) => {
    return (<ProgressBar height={height} {...rest} >
        <Progress
            {...rest}
            style={{ ...rest.style, width: `${progress}%`, background: alarm ? theme.palette.yellow : theme.palette.green }}
        />
    </ProgressBar>)
};