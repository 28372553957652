import { useState } from "react";
import FormSelectInput from "./FormSelectInput";
import theme from "../common/theme";

export const tzOptions = [
    { value: -7, label: 'Pacific Daylight Time' },
    { value: -6, label: 'Mountain Daylight Time' },
    { value: -5, label: 'Central Daylight Time' },
    { value: -4, label: 'Eastern Daylight Time' },
    { value: -3, label: 'Atlantic Daylight Time' },
    { value: -2.5, label: 'Newfoundland Daylight Time' },
]

export const FormSelectTimezone = ({ setValue, ...props }) => {
    const [defaultOptions, setDefaultOptions] = useState(tzOptions);

    const loadOptions = async () => {
        return Promise.resolve(tzOptions)
    }

    return (
        <FormSelectInput
            name="utcOffset"
            placeholder="Select Timezone"
            label="Timezone"
            defaultOptions={defaultOptions}
            loadOptions={loadOptions}
            {...props}
        />
    )
}