import { faPencil, faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { orderBy } from 'lodash';
import { formatPhoneNumber } from "react-phone-number-input";
import styled from "styled-components";
import theme from "../common/theme";
import { ButtonBase } from "./Buttons";

export const ERPActions = ({ onEditClick }) => {
    return (
        <ButtonBase onClick={async (e) => {
            e.stopPropagation();
            if (onEditClick) {
                await onEditClick();
            }
        }}><FontAwesomeIcon icon={faPencil} /></ButtonBase>
    );
}

const noop = () => { };

const ERPContactList = styled.div`
    display: flex;
    flex-direction: column;
`

const ERPContact = styled.div`
    display: flex;
    flex-direction: row;
    padding: 1rem;
    align-items: center;
    gap: 1rem;

    background-color: black;

    &:nth-child(even) {
        background-color: transparent;
    }

    &:hover {
        background-color: ${theme.palette.border};
        color: white;
    }

    span {
        filter: ${props => props.dataPrivacy ? 'blur(0.4rem)' : 'none'};

        &:not(:last-child) {
            margin-right: auto;
        }
    }
`

export const ERPEmergencyContactList = ({ contacts, dataPrivacy, onClick }) => {
    return (
        <ERPContactList>
            {orderBy(contacts, 'priority', 'asc').map(contact => (
                <ERPContact
                    key={contact.id}
                    onClick={() => onClick && onClick(contact)}
                    dataPrivacy={dataPrivacy}
                >
                    <FontAwesomeIcon icon={faPhoneSquare} />
                    <span>{contact.name}</span>
                    <span>{formatPhoneNumber(contact.phone)}</span>
                </ERPContact>
            ))}
        </ERPContactList>
    );
}