import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../common/assets/feature_image.jpeg';
import { forgotPassword, getTOTPCode, login, newPassword } from '../common/slices/auth';
import { Container, FormContainer, Input, LoginContainer, Logo } from './Auth';
import { ButtonBase, DarkButton } from './Buttons';
import { Footer } from './Layout';
import { H1 } from './Typography';


const ScreenNewPassword = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();

    const onSubmit = async (values) => {
        try {
            const { isSignedIn } = await dispatch(newPassword(values.password));
            if (isSignedIn) {
                nav('/');
            }
        } catch (ex) {
            setError("password", { message: ex.message });
        }
    }

    const {
        control,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting, isValid, isDirty },
        watch,
    } = useForm()

    const fieldProps = { control, errors };

    return (
        <Container>
            <Logo src={logo} />
            <LoginContainer>
                <FormContainer>
                    <H1>Set Your Password</H1>
                    <Input name="password" placeholder="Password" {...fieldProps} type="password" />
                    <DarkButton onClick={handleSubmit(onSubmit)} disabled={isSubmitting} >Set Password</DarkButton>
                    <ButtonBase style={{ color: 'white' }} onClick={() => nav('/auth/login')}>Back to Login</ButtonBase>
                </FormContainer>
            </LoginContainer>
            <Footer />
        </Container>
    )
}

export default ScreenNewPassword;
