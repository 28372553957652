import { generateClient } from "aws-amplify/api";
import { useEffect, useState } from 'react';
import { shiftScheduleByEmail } from '../common/graphql/queries';
import FormSelect from "./FormSelect";

const client = generateClient();

const FormSelectSchedule = ({ userId, ...props }) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        if (userId) {
            (async () => {
                const resp = await client.graphql({
                    query: shiftScheduleByEmail,
                    variables: {
                        email: userId
                    }
                })
                setOptions([
                    { value: '', label: "Select Schedule" },
                    ...resp.data.shiftScheduleByEmail.items.map(s => ({
                        value: s.id,
                        label: s.name
                    }))
                ])
            })()
        }
    }, [userId])

    return (
        <FormSelect name="scheduleId" label="Shift Schedule" options={options} {...props} selectedIndex={0} />
    )
}

export default FormSelectSchedule;