import theme from "../common/theme";
import { P, P2 } from "./Typography";

const Details = ({ data, title, style, dataPrivacy }) => (
    <div style={{ flex: 1, margin: '0.25rem 0', ...style }}>
        <P2 style={{ color: theme.palette.fontDark, margin: 0, padding: 0 }}>{title}</P2>
        <P style={{ padding: 0, margin: 0, filter: dataPrivacy ? 'blur(0.4rem)' : 'none' }}>{data}</P>
    </div>
)

export default Details;