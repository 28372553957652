import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { fetchCompany, fetchMeterSummary, fetchPendingInvoice, fetchPortalSession, selectActiveCompany, selectMeterSummary, selectPendingInvoice } from "../common/slices/company"
import { TabContent } from "./Tabs"
import styled from "styled-components"
import { H1, H2, P, P2 } from "./Typography"
import { BlueButton, ButtonBase } from "./Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCcStripe } from '@fortawesome/free-brands-svg-icons'
import moment from 'moment'

import { BarChart, Bar, ResponsiveContainer, XAxis, Tooltip, YAxis, Legend, CartesianGrid } from 'recharts';
import theme from "../common/theme"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import Row from "./Row"
import Divider from "./Divider"
import Details from "./Details"
import { selectUser } from "../common/slices/user"
import { ErrorContext, PrivacyContext } from "../App"
import { useForm } from "react-hook-form"
import FormSelectCompany from "./FormSelectCompany"

const Content = styled.div`
    flex: 1;
    background: rgba(41, 44, 84, 0.5);
    padding: 1rem;
    // margin: 0 auto;
    max-width: 1200px;
    border-top: 1rem solid black;
`

const DL = styled.dl`
    display: grid;
    grid-template-columns: auto auto;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    margin: 0;
    padding: 1rem 0 0 0;
    gap: 0.5rem;

    filter: ${props => props.dataPrivacy ? 'blur(0.4rem)' : 'none'};
`

const DT = styled.dt`
`

const DD = styled.dd`
    font-weight: 700;
`

const ContentContainer = styled.div`
    background: ${theme.palette.tableRow};
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
`

const formatter = new Intl.NumberFormat('en', { style: 'currency', currency: 'cad' });

const Chart = ({ data }) => {
    const chartData = data?.map(d => ({ name: moment(d.date).utcOffset(0).format('MMM D'), value: d.aggregatedValue }))
    return (
        <ResponsiveContainer height={400} style={{ flex: 3 }}>
            <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.border} strokeWidth={2} />
                <Bar dataKey="value" fill={theme.palette.green} />
                <XAxis dataKey="name" stroke={theme.palette.font} minTickGap={100} fontWeight={700} />
                <Tooltip
                    formatter={(value, name, props) => [`${value} new licenses used`, props.payload.name]}
                    contentStyle={{ color: 'black' }}
                />
            </BarChart>
        </ResponsiveContainer>
    )
}

const TabBillingPayments = () => {
    const privacy = useContext(PrivacyContext);
    const errorContext = useContext(ErrorContext);
    const dispatch = useDispatch();
    const invoice = useSelector(selectPendingInvoice);
    const summary = useSelector(selectMeterSummary);
    const user = useSelector(selectUser);
    const company = useSelector(selectActiveCompany);
    const [loading, setLoading] = useState(false);
    const [loadingUrl, setLoadingUrl] = useState(false);

    const { watch, control, formState: { errors } } = useForm({});
    const operCompany = watch('company');

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                if (operCompany?.value) {
                    await dispatch(fetchCompany(operCompany?.value));
                } else if (user) {
                    await dispatch(fetchCompany(user.companyId));
                }
            } catch (ex) {
                errorContext.setError(`Failed to fetch user's company.<br /><br />Message for Support: ${ex.message}`)
                setLoading(false);
            }
        })();
    }, [dispatch, user, operCompany])

    useEffect(() => {
        (async () => {
            try {
                if (company) {
                    if (!company.customerId) throw new Error("Company does not have stripe customerId assigned");
                    await dispatch(fetchPendingInvoice(company.customerId));
                    setLoading(false);
                }
            } catch (ex) {
                errorContext.setError(`Failed to fetch pending invoice.<br /><br />Message for Support: ${ex.message}`)
            }
        })();
    }, [dispatch, company])

    useEffect(() => {
        if (invoice) {
            dispatch(fetchMeterSummary(moment(invoice.start).utcOffset(0).startOf('d'), moment(invoice.end).utcOffset(0).startOf('d'), company.customerId));
        }
    }, [dispatch, invoice])

    const viewStripe = async () => {
        setLoadingUrl(s => true);
        const url = await dispatch(fetchPortalSession(company.customerId));
        window.open(url, "_blank");
        setLoadingUrl(s => false);
    }

    const sum = summary?.reduce((sum, summary) => sum += summary.aggregatedValue, 0);

    // <P2>{sum} Licenses - {moment(invoice?.start).format("MMM D")} to {moment(invoice?.end).format("MMM D")}</P2>
    return (
        <>
            <Row>
                <div style={{ flex: 1 }}>
                    <H2 noMargin>Manage Billing Preferences</H2>
                    <P>Oversee your billing information and payment methods. Set up pricing structures, manage subscription details, and ensure your billing preferences are always current.</P>
                </div>
                <div style={{ flex: 1, filter: privacy ? 'blur(0.4rem)' : 'none' }}>
                    {
                        user.role === "OPERATOR" &&
                        <FormSelectCompany control={control} errors={errors} name="company" label="Operator Select Company to View" placeholder="Select a Company to view their pending invoice" role={user.role} />
                    }
                </div>
            </Row>
            <Row style={{ alignItems: 'stretch', marginTop: '1rem' }}>
                <ContentContainer style={{ flex: 1, maxWidth: '350px', minWidth: '250px' }}>
                    <H2>Upcoming Invoice {invoice?.due ? `- ${moment(invoice?.due).format("MMM D YYYY")}` : ''}</H2>
                    <Details dataPrivacy={privacy} style={{ flex: 0 }} data={company?.name} />

                    <Details style={{ flex: 0 }} title="Invoice Period" data={`${moment(invoice?.start).format("MMM D, h:mm a")} - ${moment(invoice?.end).format("MMM D, h:mm a")}`} />
                    <Details style={{ flex: 0 }} title="Usage" data={`${sum} Licenses`} />

                    <Divider />

                    <DL dataPrivacy={privacy}>
                        {
                            invoice?.due !== null && <>
                                <DT>Payment Date</DT>
                                <DD>{moment(invoice?.due).format("MMM D YYYY, h:mm a")}</DD>
                            </>
                        }
                        {
                            invoice?.discount !== 0 && <>
                                <DT>Discount</DT>
                                <DD>{formatter.format(invoice?.discount)}</DD>
                            </>
                        }
                        <DT>Tax</DT>
                        <DD>{formatter.format(invoice?.tax)}</DD>
                        <DT>Total</DT>
                        <DD>{formatter.format(invoice?.total)}</DD>
                    </DL>
                    <BlueButton
                        onClick={viewStripe}
                        disabled={loadingUrl || loading}
                        style={{ display: 'flex', alignItems: 'center', gap: '1rem', color: 'white', marginTop: 'auto' }}
                    >
                        <FontAwesomeIcon icon={faCcStripe} size="2x" /> Manage Billing
                    </BlueButton>
                </ContentContainer>
                <ContentContainer style={{ flex: 3 }}>
                    <H2>Monthly Usage</H2>
                    <Chart data={summary} />
                </ContentContainer>
            </Row>
        </>
    )
}

export default () => (
    <TabContent tabId={1}>
        <TabBillingPayments />
    </TabContent>
)