import { ErrorMessage } from '@hookform/error-message';
import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import styled from "styled-components";
import theme from "../common/theme";
import { Label, P } from "./Typography";

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    // margin: 1rem 0;
`

export const UncontrolledSelectInput = forwardRef(({ errors, label, containerStyle, controlStyle, optionStyle, singleValueStyle, loadOptions, disabled, ...rest }, ref) => {
    return (
        <Container style={containerStyle}>
            {label && <Label>{label}</Label>}
            <AsyncSelect
                defaultOptions
                cacheOptions
                isDisabled={disabled}
                loadOptions={loadOptions}
                menuPortalTarget={document.body}
                styles={{
                    control: (styles) => ({
                        ...styles,
                        borderRadius: 0,
                        border: 'none',
                        cursor: 'pointer',
                        border: `none`,
                        background: disabled ? theme.palette.tableActive : theme.palette.tableRow,
                        padding: '0.5rem',
                        ...controlStyle,
                    }),
                    option: (styles) => ({
                        ...styles,
                        ...optionStyle,
                        color: disabled ? theme.palette.placeholder : theme.palette.font,
                        background: theme.palette.tableRow,
                        "&:hover": {
                            background: theme.palette.tableActive,
                        }
                    }),
                    multiValueRemove: (styles) => ({
                        ...styles,
                        background: 'rgba(96, 96, 96, 1)',
                        color: theme.palette.tableRow,
                        borderRadius: 0,
                        cursor: 'pointer',

                        "&:hover": {
                            background: theme.palette.tableActive,
                            color: 'inherit',
                        }
                    }),
                    input: (styles) => ({ ...styles, color: theme.palette.font }),
                    menuPortal: (styles) => ({ ...styles, zIndex: 1200, borderRadius: 0 }),
                    menu: (styles) => ({ ...styles, background: theme.palette.tableRow }),
                    singleValue: (styles) => ({ ...styles, ...singleValueStyle, color: disabled ? theme.palette.placeholder : theme.palette.font, padding: 0 })
                }} {...rest} />
            {errors && <ErrorMessage
                errors={errors}
                name={rest.name}
                render={({ message }) => <P style={{ color: 'red' }}>{message}</P>}
            />}
        </Container>
    )
})

export default ({ name, control, rules, ...rest }) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { ...fieldProps } }) => (
                <UncontrolledSelectInput {...fieldProps} {...rest} />
            )}
        />
    );
};
