import { generateClient } from "aws-amplify/api";
import { onUpdateUser } from "../graphql/subscriptions";

const client = generateClient();

export default async (email, next, error) => {
    try {
        return client.graphql({
            query: onUpdateUser,
            variables: { filter: { email: { eq: email } } },
        }).subscribe({
            next,
            error,
        });
    } catch (ex) {
        console.warn("subscribe to user updates failed", ex);
    }
};
