import { generateClient } from "aws-amplify/api";
import { onUpdateCheckIn } from "../graphql/customQueries";

const client = generateClient();

export default async (next, error) => {
    try {
        return await client.graphql({
            query: onUpdateCheckIn,
            variables: {
                filter: null,
                owner: null,
            }
        }).subscribe({
            next,
            error,
        });
    } catch (ex) {
        console.warn("subscribe to user updates failed", ex);
    }
};
