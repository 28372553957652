import { faRemove, faSignOut, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { insertSatelliteDevice, modifySatelliteDevice, removeSatelliteDevice, selectActiveDevice, setActiveSatelliteDevice } from "../common/slices/satelliteDevice";
import { selectUser } from "../common/slices/user";
import { BlueButton, ButtonBase, RedButton } from "./Buttons";
import FormPhoneNumber from "./FormPhoneNumber";
import FormSelectSatelliteDeviceType from "./FormSelectSatelliteDeviceType";
import FormSelectUser from "./FormSelectUser";
import FormTextInput from "./FormTextInput";
import Modal, { Container, Footer, Header } from './Modal';
import { H1 } from "./Typography";
import { useContext, useEffect, useState } from "react";
import Row from "./Row";
import ModalConfirm from "./ModalConfirm";
import FormIMEIInput from "./FormIMEIInput";
import { PrivacyContext } from "../App";

const unmarshall = ({ company, updatingUser, creatingUser, assignedUser, __typename, ...values }) => ({
    createdAt: new Date().toISOString(),
    ...values,
    assignedTo: values.assignedTo?.value || null,
    updatedAt: new Date().toISOString(),
})

const marshall = (values, user) => ({
    createdBy: user.email,
    companyId: user.companyId,
    ...values,
    assignedTo: values?.assignedUser ? { value: values?.assignedUser?.email, label: `${values?.assignedUser?.firstName} ${values?.assignedUser?.lastName} (${values?.assignedUser?.email})` } : null,
    updatedBy: user.email,
})

const ModalManageSatelliteDevice = ({ onClose }) => {
    const privacy = useContext(PrivacyContext);
    const dispatch = useDispatch();
    const device = useSelector(selectActiveDevice);
    const user = useSelector(selectUser);
    const [showConfirm, setShowConfirm] = useState(false);

    useEffect(() => {
        return () => dispatch(setActiveSatelliteDevice(null));
    }, [])

    const onSaveClick = async (values) => {
        try {
            const toUpdate = unmarshall(values);

            if (!toUpdate.id) {
                await dispatch(insertSatelliteDevice(toUpdate))
            } else {
                await dispatch(modifySatelliteDevice(toUpdate))
            }
            onClose();
        } catch (ex) {
            console.log(ex);
            window.alert(ex.message);
        }
    }

    const onDeleteConfirm = async () => {
        try {
            await dispatch(removeSatelliteDevice({ id: device.id }));
            onClose();
        } catch (ex) {
            window.alert(`Failed to delete: ${ex.message}`);
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors, isValid, isSubmitting, isDirty },
        setValue,
        setError,
        watch
    } = useForm({
        values: marshall(device, user),
        mode: "onChange"
    })

    const fieldProps = { control, errors };

    const brand = watch("brand");

    return (
        <Modal
            show
            size="sm"
            onClose={() => {
                if (!isDirty) {
                    onClose();
                }
            }}
        >
            <Container dataPrivacy={device?.id && privacy}>
                <Header>
                    <H1 onClick={() => navigator.clipboard.writeText(device?.imei)}>Manage Satellite Device</H1>
                    <ButtonBase onClick={onClose} disabled={isSubmitting}><FontAwesomeIcon icon={faTimesCircle} size={'2x'} /></ButtonBase>
                </Header>
                <FormIMEIInput {...fieldProps} name="imei" placeholder="Enter Device IMEI (15 digits)" label="Device IMEI" disabled={device?.id} />
                <FormSelectSatelliteDeviceType {...fieldProps} rules={{ required: true }} name="brand" label="Device Brand" />
                <FormPhoneNumber {...fieldProps} name="phoneNumber" placeholder="Enter Device Phone Number" label="Device Phone Number" defaultCountry="CA" rules={{ required: brand === 'ZOLEO' }} />
                <Row>
                    <FormSelectUser {...fieldProps} name="assignedTo" placeholder="Assign Device to a User" label="Assigned User" role={user.role} companyId={user.companyId} />
                    <ButtonBase onClick={() => setValue('assignedTo', "")}><FontAwesomeIcon icon={faSignOut} /> Unassign</ButtonBase>
                </Row>
                <Footer>
                    <BlueButton onClick={handleSubmit(onSaveClick)} disabled={isSubmitting || !isValid}>Save</BlueButton>
                    <RedButton onClick={() => setShowConfirm(true)} disabled={isSubmitting} style={{ marginLeft: 'auto' }}>Delete</RedButton>
                    <ButtonBase onClick={onClose} disabled={isSubmitting}>Cancel</ButtonBase>
                </Footer>
            </Container>
            {
                showConfirm && <ModalConfirm
                    title="Delete Confirmation"
                    confirmButtonTitle="Confirm Delete"
                    description={`Are you sure you want to delete satellite device with IMEI ${device?.imei}?<br /><br />This action is <b>permanent and cannot be undone</b>.`}
                    onConfirm={handleSubmit(onDeleteConfirm, async () => {
                        await onDeleteConfirm();
                        setShowConfirm(false);
                    })}
                    onClose={() => setShowConfirm(false)}
                    disabled={isSubmitting}
                />
            }
        </Modal>
    )
}

export default ModalManageSatelliteDevice