import styled from "styled-components";
import Portal from "./Portal";
import theme from "../common/theme";
import { useEffect } from "react";

const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.7);
    z-index: 5;
    `;

const ModalBody = styled.div`
    display: flex;
    background-color: ${theme.palette.darkBlue};
    color: white;
    width: ${props => props.size === 'lg' ? '90%' : '45%'};
    padding: 1rem;
    max-height: 95%;

    @media (max-width: 768px) {
        width: 90%;
        padding: 1rem;
    }
`;

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    filter: ${props => props.dataPrivacy ? "blur(0.4rem)" : "none"};
`

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid ${theme.palette.border};
`

export const Footer = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid ${theme.palette.border};
`

const Modal = ({ show, children, onClose, size = 'lg', bodyStyle, containerStyle }) => {
    useEffect(() => {
        let scroll = window.scrollY;
        window.scrollTo({ top: 0 });
        window.document.body.style.overflow = 'hidden';
        return () => {
            window.document.body.style.overflow = 'auto';
            window.scrollTo({ top: scroll })
        }
    }, [])

    if (!show) {
        return null;
    }

    return (
        <Portal
            node={document.getElementsByTagName('body')[0]}
        >
            <ModalContainer onClick={onClose} style={containerStyle}>
                <ModalBody onClick={(e) => e.stopPropagation()} size={size} style={bodyStyle}>
                    {children}
                </ModalBody>
            </ModalContainer>
        </Portal>
    )
}

export default Modal