import OFF from '../assets/OFF.png';
import SHIFT from '../assets/SHIFT.png';
import SHIFT_ACCIDENT from '../assets/SHIFT_ACCIDENT.png';
import SHIFT_NOT_RESPONDING from '../assets/SHIFT_NOT_RESPONDING.png';
import SHIFT_OK from '../assets/SHIFT_OK.png';
import SHIFT_PANIC from '../assets/SHIFT_PANIC.png';

import { Marker } from "@react-google-maps/api";
import isNotification from "../common/utils/isNotification";


const statusMap = {
    SHIFT,
    SHIFT_OK,
    SHIFT_PANIC,
    SHIFT_NOT_RESPONDING,
    SHIFT_ACCIDENT,
    OFF
}


const createKey = (checkIn) => {
    return checkIn.userId;
}


const CheckInMarker = ({ checkIn, privacy, onClick, ...props }) => {
    return (
        <Marker
            {...props}
            key={createKey(checkIn)}
            position={checkIn?.location}
            onClick={() => onClick && onClick(checkIn)}
            label={{
                text: `${checkIn?.user?.firstName} ${checkIn?.user?.lastName}`,
                className: isNotification(checkIn?.status) ? `map-notification ${privacy ? "privacy" : null}` : `map-label ${privacy ? "privacy" : null}`,
                fontFamily: 'SF Pro',
            }}
            icon={{
                url: statusMap[checkIn.status],
                scaledSize: new window.google.maps.Size(35, 35),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(17, 35),
                labelOrigin: new window.google.maps.Point(100, 17),
            }}
        />
    )
}

export default CheckInMarker;